import {initializeApp} from 'firebase/app';
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    getDocs,
    getFirestore,
    increment,
    limit,
    query,
    setDoc,
    updateDoc,
    where
} from 'firebase/firestore/lite';

const firebaseConfig = {
    apiKey: "AIzaSyACcazeP0gJ03twLPKF6oFrC8FkdI21Fhs",
    authDomain: "maxim-tool.firebaseapp.com",
    projectId: "maxim-tool",
    storageBucket: "maxim-tool.appspot.com",
    messagingSenderId: "713454259836",
    appId: "1:713454259836:web:ebfc5f44e4b73f4db7c3f9",
    measurementId: "G-4GM89ZSB1C"
};

const app = initializeApp(firebaseConfig);
const firebase = getFirestore(app);

export async function getDeals() {
    const dealsCol = collection(firebase, 'deals');
    const dealSnapshot = await getDocs(dealsCol);
    const dealList = dealSnapshot.docs.map(doc => doc.data());
    return dealList;
}

export const addDeal = async (deal) => {
    try {
        const docRef = await addDoc(collection(firebase, "deals"), {
            opportunity_id: deal.opportunityID,
            opportunity_name: deal.opportunityName,
            execution_id: deal.executionID,
            execution_date_time: deal.executedAt,
            down_payment: deal.root$down_payment,
            amount: deal.root$amount,
            nil: deal.root$nil,
            term: deal.root$term,
            score: deal.scoring_engine_score?.value,
            pd: deal.pd,
            pd_risk_rating: deal.pd_risk_rating?.value,
            score_decision: deal.score_decision?.value,
            final_decision: deal.final_decision,
            assets: deal.assets,
            calculations: deal.calculations,
            year: deal.year,
            month: deal.month,
            timeStamp: deal.date
        });
        // console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export const searchDeal = async (executionID) => {
    const dealsRef = collection(firebase, "deals");
    const q = query(dealsRef, where("execution_id", "==", executionID));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.empty){
        return -1;
    } else {
        let docData
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            const id = doc.id;
            docData = {
                id,
                ...doc.data()
            };
        });
        return docData;
    }
}

export const fetchDealData = async (execution_id) => {
    const dealsRef = collection(firebase, "deals");

    const q = query(
        dealsRef,
        where("execution_id", "==", execution_id),
        limit(1)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
        const document = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
        return document;
    }
    return null;
};

export const updateDealCalcs = async (id, calculations) => {

    const calc = calculations;
    const dealRef = doc(firebase, "deals", id);

    // await updateDoc(dealRef, {
    //     calculations: arrayUnion(calc)
    // });
    await updateDoc(dealRef, {
        calculations: calc
    });

}

export const updateDeal = async (deal) => {

    const id = deal.id;
    const calcs = deal.calculations;
    const tmpYear = deal.year;
    const tmpMonth = deal.month;
    const tmpTime = deal.date;

    const dealRef = doc(firebase, 'deals', id);
    await setDoc(dealRef, {calculations: calcs, year: tmpYear, month: tmpMonth, timeStamp: tmpTime}, {merge: true});

}

export const deleteDealCalcs = async (id, calculations) => {

    const calc = calculations

    const dealRef = doc(firebase, "deals", id);

    await updateDoc(dealRef, {
        calculations: arrayRemove(calc)
    });

}

export const addLog = async (executionID, obj) => {
    try {
        const dealReportRef = collection(firebase, "dealsReports");
        const q = query(dealReportRef, where("executionID", "==", executionID), where("year", "==", obj.year), where("month", "==", obj.month));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            const docRef = await addDoc(collection(firebase, "dealsReports"), {
                month: obj.month,
                executionID: executionID,
                year: obj.year,
                count: 1
            });
        } else {
            querySnapshot.forEach((log) => {
                const id = log.id;
                const reportRef = doc(firebase, "dealsReports", id);
                updateDoc(reportRef, { count: increment(1) });
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export const getReport = async (obj) => {
    const dealReportRef = collection(firebase, "dealsReports");

    const qSameYear = query(dealReportRef,
        where('year', '==', obj.startDate.getFullYear()),
        where('month', '==', obj.startDate.getMonth() + 1),
    );

    const qSameYearEnd = query(dealReportRef,
        where('year', '==', obj.endDate.getFullYear()),
        where('month', '==', obj.endDate.getMonth() + 1),
    );

    const calculateTotalCount = (querySnapshot) => {
        let totalCount = 0;
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            totalCount += data.count || 0; // Assuming count is a numeric field
        });
        return totalCount;
    };

    try {
        const [resultsSameYear, resultsSameYearEnd] = await Promise.all([getDocs(qSameYear), getDocs(qSameYearEnd)]);

        const countUniqueYear = resultsSameYear.size;
        const countUniqueYearEnd = resultsSameYearEnd.size;
        const totalCount = obj.startDate.getFullYear() === obj.endDate.getFullYear() ? countUniqueYear : countUniqueYear + countUniqueYearEnd;
        const totalCountSameYear = calculateTotalCount(resultsSameYear);
        const totalCountSameYearEnd = calculateTotalCount(resultsSameYearEnd);
        const totalOverallCount = obj.startDate.getFullYear() === obj.endDate.getFullYear() ? totalCountSameYear : totalCountSameYear + totalCountSameYearEnd;
        return {
            uniqueCount: totalCount,
            overallCount: totalOverallCount
        };
    } catch (error) {
        console.error('Error getting documents:', error);
        throw error;
    }
}

export const fetchDealsWithExecutionID = async (executionIDs) => {
    try {
        // The maximum number of executionIDs that can be used in a single query
        const BATCH_SIZE = 30;

        let allDeals = [];
        const numBatches = Math.ceil(executionIDs.length / BATCH_SIZE);  // Calculate the number of batches required

        // Loop through each batch of executionIDs and perform queries
        for (let i = 0; i < numBatches; i++) {
            const batch = executionIDs.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE);  // Get the current batch of executionIDs

            // Query Firestore for deals where execution_id matches any of the executionIDs in the current batch
            const dealsQuery = query(
                collection(firebase, 'deals'),
                where('execution_id', 'in', batch)  // Filter deals by the current batch of executionIDs
            );

            const querySnapshot = await getDocs(dealsQuery);

            // Extract the required data from each deal document
            const dealsData = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    pd: data.pd,
                    execution_id: data.execution_id,
                    nil: data.nil,
                    down_payment: data.down_payment,
                    calculations: data.calculations ? {
                        tableData: data.calculations[0].tableData,  // Extract deal object
                        modelData: data.calculations[0].model,  // Extract deal object
                        pAndL: data.calculations[0]['p&l'],  // Extract p&l object
                    } : null,
                };
            });

            // Append the deals data from the current batch to the final array
            allDeals = [...allDeals, ...dealsData];
        }

        return allDeals;  // Return the combined deals data from all batches
    } catch (error) {
        console.error("Error fetching deals with matching executionID:", error);
        return [];  // Return an empty array in case of error
    }
};

export const fetchRecentDealsReports = async () => {
    try {
        // Get the current date
        const currentDate = new Date();

        // Calculate the date for 3 months ago
        const threeMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
        const currentMonth = currentDate.getMonth() + 1;  // Firestore months are 0-indexed
        const currentYear = currentDate.getFullYear();

        // Calculate the year and month range for the last three months
        let months = [];
        let years = [];

        // Create an array of months and years for the last three months
        for (let i = 0; i < 3; i++) {
            const year = threeMonthsAgo.getFullYear();
            const month = threeMonthsAgo.getMonth() + 1; // Firestore months are 0-indexed, so +1
            months.push(month);
            years.push(year);

            // Move the date backward by 1 month
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 1);
        }

        // Query Firestore for dealsReports where the month and year are within the last three months
        const dealsReportsQuery = query(
            collection(firebase, 'dealsReports'),
            where('month', 'in', months),  // Filter by month
            where('year', 'in', years)    // Filter by year
        );

        const querySnapshot = await getDocs(dealsReportsQuery);

        const executionIDs = querySnapshot.docs.map(doc => doc.data().executionID);

        return executionIDs;  // Return an array of executionIDs
    } catch (error) {
        console.error("Error fetching recent deals reports:", error);
        return [];  // Return an empty array in case of error
    }
};