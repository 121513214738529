/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Data
import dealTableData from "./data/dealTableData";
import Table from "../../examples/Tables/Table";
import {
    Alert, Autocomplete,
    FormControl,
    InputAdornment,
    OutlinedInput,
    Paper, Select, Snackbar,
    TextField, ToggleButton
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useLocation, useSearchParams} from "react-router-dom";
import {
    get_basicSpecs, get_bulkBasicSpecs, get_engines,
    get_ewBasicSpecs, get_ewModel, get_ewValue, get_ie, get_ra,
    get_residuals, get_residualvalues, get_transmissions,
    get_vin, send_deal_sf
} from "../../connection/bridgeService";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import {
    addDeal, addLog,
    deleteDealCalcs,
    fetchDealData, fetchDeals, fetchDealsWithExecutionID, fetchRecentDealsReports,
    searchDeal,
    updateDeal,
    updateDealCalcs
} from "../../connection/firebase";
import SoftAlert from "../../components/SoftAlert";
import MenuItem from "@mui/material/MenuItem";
import { collection, getDocs } from 'firebase/firestore';
import dataDeals from "../../connection/dataDeals.json";

const paperStyle = {margin: '4px'}
const selectStyle = {margin: '4px', backgroundColor: 'transparent !important', boxShadow: 'none !important'}

function Dashboard() {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [asset, setAsset] = useState([
        {key: 'Asset type', value: 'Truck'},
        {key: 'Brand', value: 'Van der Bilt'},
        {key: 'Type', value: 'type 2'},
        {key: 'Mileage', value: '0-100k'},
        {key: 'Age equipment (years)', value: 0},
    ]);
    const [tmpObj, setTmpObj] = useState(null);
    const [tmpOpts, setTmpOpts] = useState(null);
    const [assets, setAssets] = useState([]);
    const [assetsValues, setAssetsValues] = useState([{
        index: "total",
        values: [{exposure: 0, valueLine: 0, adjValueLine: 0}]}]);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    Array.from({ length: 20 }, (_, index) => currentYear - 18 + index).reverse();
    const [deal, setDeal] = useState(location.state);
    const [openSucc, setOpenSucc] = useState(false);
    const [openSent, setOpenSent] = useState(false);
    const [openSentProblem, setOpenSentProblem] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [dealColumns, setDealColumns] = useState([
        { name: "Results", align: "left"},
        { name: "Values", align: "center"},]);
    const [annualColumns, setAnnualColumns] = useState([
        { name: " ", align: "left"},
        { name: "Year 1", align: "center"},
        { name: "Year 2", align: "center"},
        { name: "Year 3", align: "center"},
        { name: "Year 4", align: "center"},
        { name: "Year 5", align: "center"},
        { name: "Year 6", align: "center"},
        { name: "Total", align: "center"},]);
    const [dealRowsData, setDealRowsData] = useState([]);
    const [modelRowsData, setModelRowsData] = useState([]);
    const [plRowsData, setPlRowsData] = useState([]);
    const [dealHistory, setDealHistory] = useState(true);
    const [dealInfo, setDealInfo] = useState(location.state === null ? {} : location.state);
    const [dataTable, setDataTable] = useState([]);
    const [dataGraph, setDataGraph] = useState([{exposure: 0, valueline: 0},]);
    const [outstandingGapChartData, setOutstandingGapChartData] = useState({});
    const [vin, setVin] = useState();
    const [isBlDeal, setIsBlDeal] = useState(false);
    const [canEvaluate, setCanEvaluate] = useState(true);
    const [selected, setSelected] = useState(false);
    const [nilValue, setNilValue] = useState(0);
    const [feeIncome, setFeeIncome] = useState(0);
    const [open, setOpen] = useState(false);
    const [openWarning, setOpenWarning] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [residualError, setResidualError] = useState(false);
    const [scrollToDiv, setScrollToDiv] = useState(false);
    const [configuration, setConfiguration] = useState(null);
    const [configurationId, setConfigurationId] = useState();
    const [model, setModel] = useState(null);
    const [calcColumns, setCalcColumns] = useState();
    const [calcRows, setCalcRows] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isCalculating, setIsCalculating] = useState(false);
    const [canCalculate, setCanCalculate] = useState(false);
    const [isNotComplete, setIsNotComplete] = useState(false);
    const [cannotWrite, setCannotWrite] = useState(true);
    const [condition, setCondition] = useState('Poor');
    const [conditions, setConditions] = useState([]);
    const [opportunityName, setOpportunityName] = useState("");
    const [checkedIndex, setCheckedIndex] = useState(null);
    let temporal = useRef([]);
    let tempAssets = useRef([]);
    let tempOptions = useRef([{engineValue: null, transmissionValue: null, rearAxleValue: null, ieValue: null, index: 0}]);
    let tempPercentage = useRef([]);
    let riskClassPd = useRef(null);
    let riskClass = useRef(null);
    let minTargetProfit = useRef(null);
    let maxTargetProfit = useRef(null);
    let downPayPer = useRef(null);
    let equipCost = useRef(null);
    let adjEquipCost = useRef(null);
    let monthlyPayment = useRef(null);
    let originalEquipCost = useRef(null);
    let originalDownPay = useRef(null);
    let downPay = useRef(null);
    let originalNil = useRef(null);
    const nil = useRef(0);
    const periods = useRef(null);
    const annualRate = useRef(null);
    const maxAnnualRate = useRef(null);
    const minAnnualRate = useRef(null);
    const lgd = useRef(null);
    const recoveryPer = useRef(null);
    const fundingCosts = useRef(null);
    const opCosts = useRef(null);
    const otherCosts = useRef(null);

    const parseIntNumberWithSeparator = (value) => {
        if(value === undefined || value === null) return 0;
        let numericValue = 0;
        if (typeof value === 'string') {
            numericValue = value.replace(/\D/g, '');
          } else {
            numericValue = value;
          }                
        return parseInt(numericValue, 10);
    };

    const calcFeeIncome = (nil) => {
        const tempNil = parseIntNumberWithSeparator(nil);
        const tempFee = (tempNil * 7.5)/100;
        let tempFeeIn = (tempFee % 1) !== 0 ?
            (tempFee % 1) < 0.55 ?
                Math.floor(tempFee) : Math.ceil(tempFee)
            : tempFee;
        return tempFeeIn.toLocaleString().replace(/,/g, ',');
    }

    const handleIntInputChange = (value) => {
        const stringValue = String(value);
        const parts = stringValue.split(',');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join(',');
    };

    const formatValue = (value) => {
        return String(value.toFixed(2)).replace('.', ',');
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSucc(false);
    };

    const handleCloseSent = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSent(false);
    };

    const handleScroll = () => {
        setScrollToDiv(true);
    };

    const handleCloseDel = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDel(false);
    };

    const handleCloseProblem = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSentProblem(false);
    };

    const handleNewSpec = (spec, id, index) => {
      let options = tempOptions.current;
      options.map(opSpec => {
         if (opSpec.index === index) {
             if (id === "engineSelect") {
                 opSpec.engineValue = spec !== null && spec.optionValue;
             } else if (id === "transOptions") {
                 opSpec.transmissionValue = spec !== null && spec.optionValue;
             } else if (id === "raOptions") {
                 opSpec.rearAxleValue = spec !== null && spec.optionValue;
             } else if (id === "ieOptions") {
                 opSpec.ieValue = spec !== null && spec.optionValue;
             }
         }
      });
    };

    const handleConfiguration = async (configuration, index, specsOptions) => {
        setIsCalculating(true);
        setConfiguration(configuration);
        setConfigurationId(configuration.configurationId);
        const basicEngine = configuration?.specs.find(e => e.specName === "optionEngine")?.specValue || 0;
        const basicTransmission = configuration?.specs.find(e => e.specName === "optionTransmission")?.specValue || 0;
        const basicRearAxle = configuration?.specs.find(e => e.specName === "optionRearAxle")?.specValue || 0;
        const engineValue = specsOptions?.enginesOptions.find(e => e.optionName === basicEngine)?.optionValue || 0;
        const transmissionValue = specsOptions?.transOptions.find(e => e.optionName === basicTransmission)?.optionValue || 0;
        const rearAxleValue = specsOptions?.rearAxleOptions.find(e => e.optionName === basicRearAxle)?.optionValue;
        const graphValues = await getResidualValues(configuration, index);
        const basicSpecVals = {
            engineValue: engineValue,
            transmissionValue: transmissionValue,
            rearAxleValue: rearAxleValue
        };
        const assetValue = {
            index: index,
            values: graphValues,
            options: specsOptions,
            basicSpecVals: basicSpecVals
        };
        setTmpObj(assetValue);
        setIsCalculating(false);
    }

    const handleModel = async (model, index, modelYear) => {
        setModel(model);
        const graphValue = await getResidualValues(model, index, modelYear);
        const assetValue = {
            index: index,
            values: graphValue
        };
        setTmpObj(assetValue);
    }

    const handleDeal = async (calc) => {
        const nil = calc.tableData.find(row => row.name === "nilVal");
        const doPay = calc.tableData.find(row => row.name === "doPay");
        const term = calc.tableData.find(row => row.name === "periods");
        const anRate = calc.tableData.find(row => row.name === "annualRate");
        const maxAnRate = calc.tableData.find(row => row.name === "maxAnnualRate");
        const minAnRate = calc.tableData.find(row => row.name === "minAnnualRate");
        const newEquipCost = calc.tableData.find(row => row.name === "equipCost");
        const monthPay = calc.tableData.find(row => row.name === "paymentAmount");
        const oAmount = parseIntNumberWithSeparator(equipCost.current.value);
        if (calc.assets !== undefined) {
            const oldAssets = await JSON.parse(calc.assets);
            if (oldAssets.length > 0) {
                const newConditions = temporal.current.map((asst,index) => {
                    return oldAssets[index].condition
                });
                temporal.current = newConditions;
                setConditions(newConditions);
            }
        }
        setConfigurationId(calc.configurationId);
        setDataGraph(calc.graphData);
        setDealRowsData(calc[`deal`]);
        setModelRowsData(calc[`model`]);
        setPlRowsData(calc[`p&l`]);
        setConfiguration(calc.configurations);
        setNilValue(handleIntInputChange(nil.value));
        setCondition(calc.condition);
        downPay.current.value = handleIntInputChange(doPay.value);
        equipCost.current.value = handleIntInputChange(newEquipCost.value)
        downPayPer.current.value = ((doPay.value * 100) / oAmount).toFixed(2);
        monthlyPayment.current.value = handleIntInputChange(Math.round(monthPay.value));
        periods.current.value = term.value;
        annualRate.current.value = (anRate.value * 100).toFixed(2);
        maxAnnualRate.current.value = maxAnRate !== undefined ? (maxAnRate.value*100).toFixed(2) : (parseFloat(annualRate.current.value) + parseFloat(maxTargetProfit.current.value)).toFixed(2);
        minAnnualRate.current.value = minAnRate !== undefined ? (minAnRate.value*100).toFixed(2) : (parseFloat(anRate.value * 100).toFixed(2));
        setCanCalculate(true);
    }

    const parseValue = (value) => {
        const cleanedValue = value.replace(/[^\d.-]/g, '');
        return parseFloat(cleanedValue);
    };

    const sendDeal = async (calc, dealData) => {
        setIsSending(true);
        const scoring_id = dealData.scoring_id !== undefined ? dealData.scoring_id : dealData.scoringID;
        const annualRate = calc.deal.find(row => row.Results === "Approved Annual Rate");
        const minAnnualRate = calc.deal.find(row => row.Results === "Min Annual Rate");
        const maxAnnualRate = calc.deal.find(row => row.Results === "Max Annual Rate");
        const downPayment = calc.deal.find(row => row.Results === "DownPayment");
        const downPaymentPer = calc.deal.find(row => row.Results === "% DownPayment");
        const term = calc.deal.find(row => row.Results === "Term");
        const ldg = calc.model.find(row => row.Results === "LGD");
        const el = calc.model.find(row => row.Results === "EL");
        const body = {
            Max_Annual_Rate__c: maxAnnualRate.Values,
            minAnnualRate__c: minAnnualRate.Values,
            Approved_Annual_Rate__c : annualRate.Values,
            Down_Payment__c: downPayment.Values,
            Down_Payment_Percent__c: downPaymentPer.Values,
            Term__c: term.Values,
            LGD_New_Pricing__c: ldg.Values,
            EL__c: el.Values,
        };
        const formatedBody = {};
        for (const key in body) {
            if (body.hasOwnProperty(key)) {
                formatedBody[key] = parseValue(body[key]);
            }
        }
        try {
            await send_deal_sf(scoring_id, formatedBody).then( r => {
                    if (r) {
                        setOpenSent(true);
                    }
                }
            );
        } catch (e) {
          console.log(e);
          setOpenSentProblem(true);
        }
        setIsSending(false);
    };

    const deleteDeal = (calc, dealData) => {
        deleteDealCalcs(dealData.id, calc).then(r => {
            setOpenDel(true);
        });
        const newCalcs = [];
        const calcHis = dealData.calculations;
        calcHis.forEach(calculation => {
            if (calculation.calcId !== calc.calcId){
                newCalcs.push(calculation);
            }
        });
        const updatedDealInfo = {
            ...dealData,
            calculations: newCalcs,
        };
        setDealInfo(updatedDealInfo);
        displayCalcs(newCalcs, updatedDealInfo);
    }

    const handleChange = (e, index) => {
        const val = e.target.value;
        const change = conditions.map((cond, indx) => {
            return indx === index ? val : cond;
        });
        setConditions(change);
    }

    useEffect(() => {
        const newAssets = assets.map((assetInfo,index) => {
            return {
                ...assetInfo,
                condition: conditions[index]
            }
        })
        setAssets(newAssets);
    }, [conditions]);

    const displayCalcs = (calcs, dealData) => {
        if (calcs !== undefined && calcs?.length > 0) {
            const tempColumns = [
                { name: 'Select', align: 'center' },
                { name: 'Delete', align: 'center' },
                { name: 'Id', align: 'center'},
                { name: 'Nil', align: 'center'},
                { name: 'Down Payment', align: 'center'},
                { name: 'Term', align: 'center'},
                { name: 'Min Annual Rate', align: 'center'},
            ];
            let tempRows = [];
            calcs.forEach(calc => {
                const nil = calc.tableData.find(row => row.name === "nilVal");
                const downPay = calc.tableData.find(row => row.name === "doPay");
                const periods = calc.tableData.find(row => row.name === "periods");
                const anRate = calc.tableData.find(row => row.name === "annualRate");
                const tempRow = {
                    Select: (<IconButton onClick={() => {
                        handleDeal(calc);
                        handleScroll();
                    }}><Icon>visibility</Icon></IconButton>),
                    Delete: (<IconButton onClick={() => {
                        deleteDeal(calc, dealData);
                    }}><Icon>delete</Icon></IconButton>),
                    Id: calc.configurationId,
                    Nil: handleIntInputChange(nil.value),
                    [`Down Payment`]: handleIntInputChange(downPay.value),
                    Term: periods.value,
                    [`Min Annual Rate`]: `${(anRate.value * 100).toFixed(2)}%`,
                }
                tempRows.push(tempRow);
            });
            setCalcColumns(tempColumns);
            setCalcRows(tempRows);
        } else {
            setDealHistory(false);
        }
    }

    const getResidualValues = async (tempConfig, tableIndex, modelYear) => {
        try {
            setResidualError(false);
            if (tempConfig.configurationId) {
                const conditionInfo = temporal.current[tableIndex];
                const params = {
                    configurationId: tempConfig.configurationId,
                    condition: conditionInfo !== undefined ? conditionInfo : condition
                }
                let graphData = {};
                await get_residualvalues(params).then(r => {
                    let tempResVal = r;
                    let retailValueYear = [];
                    let deprePercentage = [];
                    let values = {
                        financeValue: tempResVal.residualValues[0].adjustedFinanceValue,
                        retailValue: tempResVal.residualValues[0].adjustedRetailValue,
                        tradeInValue: tempResVal.residualValues[0].adjustedTradeInValue,
                        wholesaleValue: tempResVal.residualValues[0].adjustedWholesaleValue,
                    }
                    let adjRetailVal = parseInt(tempResVal.residualValues[0].adjustedRetailValue);
                    let unadjRetailVal = parseInt(tempResVal.residualValues[0].unadjustedRetailValue);
                    if (tempResVal.residualValues[0].adjustedRetailValue && adjRetailVal < unadjRetailVal) {
                        equipCost.current.value = handleIntInputChange(tempResVal.residualValues[0].adjustedRetailValue);
                        tempResVal.residualValues.map(year => {
                            retailValueYear.push(year.adjustedRetailValue);
                            deprePercentage.push(parseFloat(year.adjustedRetailPercent));
                        })
                    } else {
                        equipCost.current.value = handleIntInputChange(tempResVal.residualValues[0].unadjustedRetailValue);
                        values = {
                            financeValue: tempResVal.residualValues[0].unadjustedFinanceValue,
                            retailValue: tempResVal.residualValues[0].unadjustedRetailValue,
                            tradeInValue: tempResVal.residualValues[0].unadjustedTradeInValue,
                            wholesaleValue: tempResVal.residualValues[0].unadjustedWholesaleValue,
                        }
                        tempResVal.residualValues.map(year => {
                            retailValueYear.push(year.unadjustedRetailValue);
                            deprePercentage.push(parseFloat(year.unadjustedRetailPercent));
                        })
                    }
                    if (!tempResVal.residualValues[1].unadjustedRetailValue) {
                        let orginalVal = parseIntNumberWithSeparator(originalEquipCost.current.value);
                        setDataGraph([{exposure: 0, valueline: 0},]);
                        setResidualError(true);
                        setCannotWrite(false);
                        setCanCalculate(false);
                        equipCost.current.value = orginalVal;
                        return
                    }
                    let tmpPer = tempPercentage.current;
                    tmpPer.map(assPer => {
                        if (assPer.index === tableIndex) {
                            assPer.percentage = deprePercentage;
                        }
                    })
                    tempPercentage.current = tmpPer;
                    let monthDif = [];
                    retailValueYear.forEach((value, index) => {
                        if (index !== retailValueYear.length - 1) {

                            const current = parseInt(value);
                            const next = parseInt(retailValueYear[index + 1]);
                            const difference = current - next;
                            const divided = difference / 12;

                            monthDif.push(divided);

                        }
                    });
                    const monthValues = [];
                    retailValueYear.forEach((value, index) => {
                        const currentValue = parseInt(value);
                        let monthValue = currentValue;
                        let adjMonthValue = Math.round(currentValue * (parseFloat(recoveryPer.current.value)/ 100));
                        if (index < retailValueYear.length - 1) {
                            const differencePerMonth = monthDif[index];
                            let difPerMonth = (differencePerMonth % 1) < 0.5 ? Math.floor(differencePerMonth) : Math.ceil(differencePerMonth)
                            for (let i = 0; i < 12; i++) {
                                if (i === 0) {
                                    const tempValues = {
                                        valueLine: monthValue,
                                        adjValueLine: adjMonthValue
                                    };
                                    monthValues.push(tempValues);
                                } else {
                                    monthValue = monthValue - difPerMonth;
                                    adjMonthValue = Math.round(monthValue * (parseFloat(recoveryPer.current.value)/ 100));
                                    const tempValues = {
                                        valueLine: monthValue,
                                        adjValueLine: adjMonthValue
                                    }
                                    monthValues.push(tempValues);
                                }
                            }
                        }
                    });
                    adjEquipCost.current.value = handleIntInputChange(monthValues[0].adjValueLine);
                    const updatedGraph = monthValues.map((values, index) => ({
                        valueLine: values.valueLine,
                        adjValueLine: values.adjValueLine
                    }));
                    graphData = updatedGraph;
                });
                return graphData;
            } else {
                let residualPer = {};
                let graphData = {};
                await get_residuals().then(r => {
                    residualPer = r;
                })
                let params = {
                    modelId: tempConfig.modelId,
                    year: modelYear
                }
                await get_ewValue(params).then(r => {
                    let tempVal = r;
                    let tempSizeClassId = tempConfig.sizeClassId
                    const dateObj = new Date();
                    const flv = tempVal.unadjustedFlv;
                    const year = tempVal.year;
                    let residualValues = [];
                    let resPer = residualPer.find(res => res.sizeClassId === tempSizeClassId && res.valueType === 'quicksale');
                    if (resPer === undefined) {
                        setDataGraph([{exposure: 0, valueline: 0},]);
                        setResidualError(true);
                        setCannotWrite(false);
                        setCanCalculate(false);
                        equipCost.current.value = originalEquipCost.current.value;
                        return
                    }
                    let resValue = flv;
                    let tempGraph = [];
                    let yearDif = dateObj.getFullYear() - year;
                    for (let i = 0; i <= 7; i++) {
                        let percentage = resPer[yearDif] !== undefined ? resPer[yearDif] : 1;
                        resValue = i === 0 ? resValue : resValue * percentage;
                        yearDif ++;
                        residualValues.push(resValue);
                    }
                    residualValues.forEach((value, index) => {
                        let currentValue = Math.round(value);
                        let adjValue = Math.round(value * (30 / 100));
                        let nextValue = isNaN(Math.round(residualValues[index+1])) ? 0 : Math.round(residualValues[index+1]);
                        let nextAdjValue = isNaN(Math.round(residualValues[index+1] * (30 / 100))) ? 0 : Math.round(residualValues[index+1] * (30 / 100));
                        if (!isNaN(Math.round(residualValues[index+1]))) {
                            let difVal = currentValue - nextValue;
                            let difAdjVal = adjValue - nextAdjValue;
                            let currentMonthVal = difVal/12;
                            let adjMonthVal = difAdjVal/12;
                            let tempCurrVal = currentValue;
                            let tempAdjVal = adjValue;
                            for (let i = 0; i < 12; i++) {
                                tempCurrVal = i === 0 ? tempCurrVal : tempCurrVal - currentMonthVal;
                                tempAdjVal = i === 0 ? tempAdjVal : tempAdjVal - adjMonthVal;
                                let tempGraphVal = {
                                    valueLine: Math.round(tempCurrVal),
                                    adjValueLine: Math.round(tempAdjVal)
                                }
                                tempGraph.push(tempGraphVal);
                            }
                        }
                    });
                    adjEquipCost.current.value = handleIntInputChange(tempGraph[0].adjValueLine);
                    const updatedGraph = tempGraph.map((values) => ({
                        valueLine: values.valueLine,
                        adjValueLine: values.adjValueLine
                    }));
                    graphData = updatedGraph;
                    setDataGraph(updatedGraph);
                });
                return graphData;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getDeal = (doPay, nilVal, adjValueLines, tmpGraphData) => {
        let firstCalc = false;
        let tableData = [];
        let sec = {};
        let senior = {cost: 0.095, capitalPer: 0.65, returnWacc: 0};
        let subdebt = {cost: 0.11, capitalPer: 0.15, returnWacc: 0};
        const perDiff = parseFloat((1 - senior.capitalPer - subdebt.capitalPer).toFixed(2));
        let equity = {cost: 0.40, capitalPer: perDiff, returnWacc: 0};
        const wacc = parseFloat(((senior.cost * senior.capitalPer) + (subdebt.cost * subdebt.capitalPer) + (equity.cost * equity.capitalPer)).toFixed(4));
        senior.returnWacc = parseFloat(((senior.cost * senior.capitalPer)/wacc).toFixed(4));
        subdebt.returnWacc = parseFloat(((subdebt.cost * subdebt.capitalPer)/wacc).toFixed(4));
        equity.returnWacc = parseFloat(((equity.cost * equity.capitalPer)/wacc).toFixed(4));
        if (riskClassPd.current.value) {
            sec = {name: "riskClassPd", value: parseFloat(riskClassPd.current.value/100)}
            tableData.push(sec);
        }
        if (minTargetProfit.current.value) {
            sec = {name: "minTargetProfit", value: parseFloat(minTargetProfit.current.value/100)}
            tableData.push(sec);
        }
        if (maxTargetProfit.current.value) {
            sec = {name: "maxTargetProfit", value: parseFloat((maxTargetProfit.current.value/100).toFixed(2))}
            tableData.push(sec);
        }
        if (fundingCosts.current.value) {
            sec = {name: "fundingCosts", value: parseFloat(wacc/100)}
            tableData.push(sec);
            sec = {name: "fundingCostsWacc", value: wacc}
            tableData.push(sec);
            sec = {name: "senior", value: senior}
            tableData.push(sec);
            sec = {name: "subdebt", value: subdebt}
            tableData.push(sec);
            sec = {name: "equity", value: equity}
            tableData.push(sec);
        }
        if (opCosts.current.value) {
            sec = {name: "opCosts", value: parseFloat(opCosts.current.value/100)}
            tableData.push(sec);
        }
        if (otherCosts.current.value) {
            sec = {name: "otherCosts", value: parseFloat(otherCosts.current.value/100)}
            tableData.push(sec);
        }
        if (originalNil.current.value) {
            sec = {name: "originalNil", value: parseIntNumberWithSeparator(originalNil.current.value)}
            tableData.push(sec);
        }
        if (originalDownPay.current.value) {
            sec = {name: "originalDownPay", value: parseIntNumberWithSeparator(originalDownPay.current.value)}
            tableData.push(sec);
        }
        if (nilVal !== undefined) {
            sec = {name: "nilVal", value: nilVal}
            tableData.push(sec);
        }
        if (doPay !== undefined) {
            sec = {name: "doPay", value: doPay}
            tableData.push(sec);
        }
        if (periods.current.value) {
            sec = {name: "periods", value: parseFloat(periods.current.value)}
            tableData.push(sec);
        }
        if (originalEquipCost.current.value) {
            sec = {name: "originalEquipCost", value: parseIntNumberWithSeparator(originalEquipCost.current.value)}
            tableData.push(sec);
        }
        if (equipCost.current.value) {
            sec = {name: "equipCost", value: parseIntNumberWithSeparator(equipCost.current.value)}
            tableData.push(sec);
        }
        const tmpPeriods = dataTable.length !== 0 ? dataTable?.find(item => item.name === 'periods')?.value : 0;
        const tmpDownPay = dataTable.length !== 0 ? dataTable?.find(item => item.name === 'doPay')?.value : handleIntInputChange(doPay);
        const tmpEquipCost = dataTable.length !== 0 ? dataTable?.find(item => item.name === 'equipCost')?.value : 0;
        if (annualRate.current.value === '' || periods.current.value !== tmpPeriods.toString() || parseIntNumberWithSeparator(downPay.current.value).toString() !== tmpDownPay.toString() || parseIntNumberWithSeparator(equipCost.current.value).toString() !== tmpEquipCost.toString()) {
            annualRate.current.value = 15;
            firstCalc = true;
        }
        let percentageTotal = 0;
        let tmpAnnualRate = parseFloat(annualRate.current.value.replace(',', '.'));
        let totalExposure = 0;
        let totalIncome = 0;
        let totalCost = 0;
        let netProfit = 0;
        let pl = 0;
        let creditCost = 0;
        let row = [];
        do {
            let minAnRate = percentageTotal === 0 && annualRate.current.value === '' ? 15 : annualRate.current.value;
            let minMonthRate = (minAnRate/100)/12;
            let upperPart = minMonthRate * nilVal;
            let lowerPart = Math.pow((1+minMonthRate),-periods.current.value);
            let payAmount = upperPart/(1-lowerPart);
            if (payAmount) {
                sec = {name: "paymentAmount", value: payAmount}
                if (tableData.some(item => item.name === "paymentAmount")){
                    tableData = tableData.map(item => (item.name === "paymentAmount" ? sec : item));
                } else {
                    tableData.push(sec);
                }
            }
            if (annualRate.current.value) {
                sec = {name: "annualRate", value: parseFloat(annualRate.current.value/100)}
                if (tableData.some(item => item.name === "annualRate")){
                    tableData = tableData.map(item => (item.name === "annualRate" ? sec : item));
                } else {
                    tableData.push(sec);
                }
            }
            let {totalPercentage} = dealTableData(tableData, asset, adjValueLines);
            percentageTotal = totalPercentage/100;
            if (percentageTotal <= (minTargetProfit.current.value/100) && firstCalc){
                tmpAnnualRate = tmpAnnualRate + 0.1;
                annualRate.current.value = tmpAnnualRate;
            } else {
                if (percentageTotal > (minTargetProfit.current.value/100) || !firstCalc) {
                    tmpAnnualRate = tmpAnnualRate;
                    annualRate.current.value = tmpAnnualRate;
                    minAnRate = tmpAnnualRate;
                    minMonthRate = (minAnRate/100)/12;
                    upperPart = minMonthRate * nilVal;
                    lowerPart = Math.pow((1+minMonthRate),-periods.current.value);
                    payAmount = upperPart/(1-lowerPart);
                    if (payAmount) {
                        sec = {name: "paymentAmount", value: payAmount}
                        if (tableData.some(item => item.name === "paymentAmount")){
                            tableData = tableData.map(item => (item.name === "paymentAmount" ? sec : item));
                        }
                    }
                    if (annualRate.current.value) {
                        sec = {name: "annualRate", value: parseFloat(annualRate.current.value/100)}
                        if (tableData.some(item => item.name === "annualRate")){
                            tableData = tableData.map(item => (item.name === "annualRate" ? sec : item));
                        }
                    }
                    if (firstCalc) {
                        const maxRate = parseFloat(tmpAnnualRate.toFixed(2)) + parseFloat(maxTargetProfit.current.value);
                        sec = {name: "maxAnnualRate", value: parseFloat(maxRate/100)}
                        if (tableData.some(item => item.name === "maxAnnualRate")){
                            tableData = tableData.map(item => (item.name === "maxAnnualRate" ? sec : item));
                        } else {
                            tableData.push(sec);
                        }
                        const minRate = parseFloat(annualRate.current.value);
                        sec = {name: "minAnnualRate", value: parseFloat(minRate/100)}
                        if (tableData.some(item => item.name === "minAnnualRate")){
                            tableData = tableData.map(item => (item.name === "minAnnualRate" ? sec : item));
                        } else {
                            tableData.push(sec);
                        }
                    } else {
                        if (tableData.some(item => item.name === "maxAnnualRate")){
                            tableData = tableData.map(item => (item.name === "maxAnnualRate" ? dataTable?.find(item => item.name === 'maxAnnualRate') : item));
                        } else {
                            tableData.push(dataTable?.find(item => item.name === 'maxAnnualRate'));
                        }
                        if (tableData.some(item => item?.name === "minAnnualRate")){
                            tableData = tableData.map(item => (item.name === "minAnnualRate" ? dataTable?.find(item => item.name === 'minAnnualRate') : item));
                        } else {
                            tableData.push(dataTable?.find(item => item.name === 'minAnnualRate'));
                        }
                    }
                }
                setDataTable(tableData);
                let {dealRows, graphData} = dealTableData(tableData, asset, adjValueLines);
                const tmpRate = firstCalc ? minAnRate.toFixed(2) : (dataTable?.find(item => item.name === 'minAnnualRate').value *100).toFixed(2);
                if (firstCalc || parseFloat(annualRate.current.value).toFixed(2) === tmpRate) {
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle").Total = '$0';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle")[`Year 1`] = '$0';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle")[`Year 2`] = '$0';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle")[`Year 3`] = '$0';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle")[`Year 4`] = '$0';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle")[`Year 5`] = '$0';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle")[`Year 6`] = '$0';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle %").Total = '0%';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle %")[`Year 1`] = '0%';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle %")[`Year 2`] = '0%';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle %")[`Year 3`] = '0%';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle %")[`Year 4`] = '0%';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle %")[`Year 5`] = '0%';
                    dealRows.find(variables => variables[` `] === "Profit above Equity hurdle %")[`Year 6`] = '0%';
                }
                row = dealRows;
                totalExposure = parseIntNumberWithSeparator(dealRows.find(variables => variables[` `] === "Average NIL").Total);
                creditCost = parseIntNumberWithSeparator(dealRows.find(variables => variables[` `] === "Credit costs").Total);
                totalIncome = dealRows.find(variables => variables[` `] === "Total Income").Total;
                totalCost = dealRows.find(variables => variables[` `] === "Total Cost").Total;
                netProfit = parseIntNumberWithSeparator(totalIncome) - parseIntNumberWithSeparator(totalCost);
                netProfit = handleIntInputChange(netProfit);
                pl = dealRows.find(variables => variables[` `] === "Profit above Equity hurdle %").Total;
                const tmpUpdateData = tmpGraphData !== null ? tmpGraphData : dataGraph;
                adjEquipCost.current.value = handleIntInputChange(tmpUpdateData[0].adjValueLine);
                const updatedGraph = tmpUpdateData.map(( data, index) => ({
                    exposure: graphData[index] && graphData[index].exposure !== undefined ? graphData[index].exposure : 0,
                    valueLine: data.valueLine ? data?.valueLine : 0,
                    adjValueLine: data.adjValueLine ? data?.adjValueLine : 0
                }));
                const monthPay = tableData.find(section => section.name === "paymentAmount");
                monthlyPayment.current.value = handleIntInputChange(Math.round(monthPay.value));
                setDataGraph(updatedGraph);
            }
        } while (percentageTotal <= (minTargetProfit.current.value/100) && firstCalc);
        if (firstCalc) {
            const maxRate = parseFloat(tmpAnnualRate.toFixed(2)) + parseFloat(maxTargetProfit.current.value);
            const minRate = parseFloat(tmpAnnualRate.toFixed(2));
            maxAnnualRate.current.value = maxRate.toFixed(2);
            minAnnualRate.current.value = minRate.toFixed(2);
        }
        annualRate.current.value = tmpAnnualRate.toFixed(2);
        const newCols = [
            { name: "Results", align: "left"},
            { name: "Values", align: "center"},
        ];
        const dealData = [
            {
                [`Results`]: "NIL",
                [`Values`]: `$${nilValue}`
            },
            {
                [`Results`]: "DownPayment",
                [`Values`]: `$${downPay.current.value}`
            },
            {
                [`Results`]: "% DownPayment",
                [`Values`]: `${downPayPer.current.value}%`
            },
            {
                [`Results`]: "Term",
                [`Values`]: periods.current.value
            },
            {
                [`Results`]: "Monthly Payment",
                [`Values`]: `$${monthlyPayment.current.value}`
            },
            {
                [`Results`]: "Min Annual Rate",
                [`Values`]: `${minAnnualRate.current.value}%`
            },
            {
                [`Results`]: "Max Annual Rate",
                [`Values`]: `${maxAnnualRate.current.value}%`
            },
            {
                [`Results`]: "Approved Annual Rate",
                [`Values`]: `${annualRate.current.value}%`
            },
        ];
        const modelData = [
            {
                [`Results`]: "PD",
                [`Values`]: `${riskClassPd.current.value}%`
            },
            {
                [`Results`]: "PD Risk Grade",
                [`Values`]: riskClass.current.value
            },
            {
                [`Results`]: "LGD",
                [`Values`]: `${lgd.current.value}%`
            },
            {
                [`Results`]: "EL",
                [`Values`]: `${((creditCost/totalExposure)*100).toFixed(2)}%`
            },
        ]
        setDealColumns(newCols);
        setDealRowsData(dealData);
        setModelRowsData(modelData);
        setPlRowsData(row);
    };

    const getBasicSpecs = async (vinConfig, tableIndex, specsOptions) => {
        try {
            const configIdList = [];
            vinConfig.map(vin => {
                const tempConfigId = vin.configurationId;
                configIdList.push(tempConfigId);
            });
            const res = await get_bulkBasicSpecs(configIdList);
            let tempRows = [];
            let tempColumns = [
                {name: 'Actions', align: 'center'},
                {name: 'ConfigurationId', align: 'center'},
                {name: 'Engine Type', align: 'center'},
                {name: 'Engine Make', align: 'center'},
                {name: 'Engine Model', align: 'center'},
                {name: 'Transmission', align: 'left'},
                {name: 'Rear Axle', align: 'left'},
                {name: 'Axle Configuration', align: 'center'},
                {name: 'HP', align: 'center'},
                {name: 'Legacy Manufacturer', align: 'center'},
                {name: 'Legacy Model', align: 'center'},
                {name: 'Option Engine', align: 'left'},
                {name: 'Sleeper', align: 'left'},
                {name: 'Wheelbase', align: 'center'},
            ];
            res.map((r,index) => {
                const engType = r.specs.find(spec => spec.specName === 'engineType')?.specValue;
                let tempRow = {};
                tempRow = {
                    Actions: (<IconButton onClick={() => {
                        if (!isBlDeal) {
                            setIsDisabled(true);
                            setCanCalculate(true);
                            handleConfiguration(r, tableIndex, specsOptions);
                        } else {
                            setOpenWarning(true);
                        }
                    }}><Icon>search</Icon></IconButton>),
                    description: r.specs.find(spec => spec.specName === 'description')?.specValue,
                    ConfigurationId: r.configurationId,
                    ['Engine Type']: engType === 'G' ? "Gas" : engType === 'D' ? "Diesel" : engType === 'F' ? "Flex fuel gas" : engType === 'H' ? "Hybrid" : engType === 'E' ? "Electric (EV)" : engType === 'N' ? "Compressed Natural Gas" : engType,
                    ['Engine Make']: r.specs.find(spec => spec.specName === 'engineMake')?.specValue,
                    ['Engine Model']: r.specs.find(spec => spec.specName === 'engineModel')?.specValue,
                    Transmission: r.specs.find(spec => spec.specName === 'transmission')?.specValue,
                    ['Rear Axle']: r.specs.find(spec => spec.specName === 'axle2Model')?.specValue,
                    ['Axle Configuration']: r.specs.find(spec => spec.specName === 'axleconfiguration')?.specValue,
                    HP: r.specs.find(spec => spec.specName === 'hp')?.specValue,
                    ['Legacy Manufacturer']: r.specs.find(spec => spec.specName === 'legacyManufacturer')?.specValue,
                    ['Legacy Model']: r.specs.find(spec => spec.specName === 'legacyModel')?.specValue,
                    ['Option Engine']: r.specs.find(spec => spec.specName === 'optionEngine')?.specValue,
                    Sleeper: r.specs.find(spec => spec.specName === 'sleeper')?.specValue,
                    vin: r.specs.find(spec => spec.specName === 'vin')?.specValue,
                    Wheelbase: r.specs.find(spec => spec.specName === 'wheelbase')?.specValue,
                };
                tempRows.push(tempRow);
            });
            const tempTable = {
                rows: tempRows,
                columns: tempColumns,
                specsOptions: specsOptions
            }
            return tempTable;
        } catch (e) {
            console.log(e);
            setOpen(true);
        }
    }

    const getEwBasicSpecs = async (models, tableIndex, modelYear) => {
        try {
            const modelIdList = [];
            models.map(model => {
                const tempModelId = model.modelId;
                modelIdList.push(tempModelId);
            });
            let tempRows = [];
            let temp = [];
            let tempColumns = [
                {name: 'Actions', align: 'center'},
                {name: 'Model ID', align: 'center'},
                {name: 'Dozer Type', align: 'center'},
                {name: 'Engine', align: 'center'},
                {name: 'Horse Power', align: 'center'},
                {name: 'Power Mode', align: 'center'},
                {name: 'Operator Protection', align: 'center'},
                {name: 'Track Frame', align: 'center'},
                {name: 'Track Gauge', align: 'center'},
                {name: 'Track Shoe Width', align: 'left'},
                {name: 'Number Of Speeds', align: 'left'},
                {name: 'Transmission', align: 'left'},
            ];
            const promises = modelIdList.map(async modelId => {
                const modelRes = await get_ewBasicSpecs(modelId);
                temp.push(modelRes);
            });
            await Promise.all(promises);
            temp.map(r => {
                let tempRow = {
                    ['Dozer Type']: r.specs.find(spec => spec.specName === 'dozerType')?.specValue,
                    Engine: r.specs.find(spec => spec.specName === 'engine')?.specValue,
                    ['Horse Power']: r.specs.find(spec => spec.specName === 'horsepower')?.specValue,
                    ['Power Mode']: r.specs.find(spec => spec.specName === 'powerMode')?.specValue,
                    ['Operator Protection']: r.specs.find(spec => spec.specName === 'operatorProtection')?.specValue,
                    ['Track Frame']: r.specs.find(spec => spec.specName === 'trackFrame')?.specValue,
                    ['Track Gauge']: r.specs.find(spec => spec.specName === 'trackGauge')?.specValue,
                    ['Track Shoe Width']: r.specs.find(spec => spec.specName === 'trackShoeWidth')?.specValue,
                    ['Number Of Speeds']: r.specs.find(spec => spec.specName === 'numberOfSpeeds')?.specValue,
                    Transmission: r.specs.find(spec => spec.specName === 'transmission')?.specValue,
                    ['Model ID']: r.modelId,
                    Actions: (<IconButton onClick={() => {
                        setIsDisabled(true);
                        setCanCalculate(true);
                        handleModel(r, tableIndex, modelYear);
                    }}><Icon>search</Icon></IconButton>)
                };
                tempRows.push(tempRow);
            });
            const tempTable = {
                rows: tempRows,
                columns: tempColumns
            }
            return tempTable;
        } catch (e) {
            console.log(e);
            setOpen(true);
        }
    }

    const saveDeal = async () => {
        const configId = configurationId !== undefined ? configurationId : model !== null ? model.modelId : deal.executionID;
        const calc = {
            "calcId": `${configId}_00`,
            "configurationId": configId,
            "tableData": dataTable,
            "configurations": configuration !== null ? configuration : model,
            "graphData": dataGraph,
            "assets": JSON.stringify(assets),
            "deal": dealRowsData,
            "model": modelRowsData,
            "p&l": plRowsData,
        }
        if ("calculations" in dealInfo) {
            const calcs = [calc];
            await updateDealCalcs(dealInfo.id, calcs).then(r => {
                lookDeal(dealInfo.execution_id);
                setOpenSucc(true);
            });
        } else {
            const tempDeal = {
                ...dealInfo,
                "month": currentMonth,
                "year": currentYear,
                "date": Date.now(),
                "calculations": [
                    calc
                ]
            }
            if ("id" in tempDeal) {
                await updateDeal(tempDeal).then(r => {
                    lookDeal(dealInfo.execution_id);
                    setOpenSucc(true);
                    setDealHistory(false);
                });
            } else {
                await addDeal(tempDeal).then(r => {
                    lookDeal(dealInfo.executionID);
                    setOpenSucc(true);
                    setDealHistory(false);
                });
            }
        }
        await sendDeal(calc,dealInfo);
    }

    const lookDeal = async (executionID) => {
        if (executionID) {
            const dealData = await searchDeal(executionID);
            if (dealData !== -1) {
                setDealInfo(dealData);
                if ("calculations" in dealData){
                    setDealHistory(true);
                    displayCalcs(dealData.calculations, dealData);
                } else {
                    setDealHistory(false);
                }
            } else {
                setDealHistory(false);
            }
        }
    }

    const getVin = async (vin, index, assetYear) => {
        let specsTable = {};
        await get_vin(vin)
            .then(async r => {
                const vinVerification = r;
                const yearVerification = [];
                vinVerification.map(conf => {
                    const confYear = parseInt(conf.modelYear);
                    if (confYear === assetYear) {
                        yearVerification.push(conf);
                    }
                });
                if (yearVerification.length !== 0) {
                    const params = {
                        sizeClassId: yearVerification[0].sizeClassId,
                        assetYear: parseInt(yearVerification[0].modelYear),
                    }
                    const enginesOptions = await get_engines(params);
                    const transOptions = await get_transmissions(params);
                    const ieOptions = await get_ie(params);
                    const rearAxleOptions = await get_ra(params);
                    const specsOptions = {
                        enginesOptions: enginesOptions,
                        transOptions: transOptions,
                        ieOptions: ieOptions,
                        rearAxleOptions: rearAxleOptions
                    }
                    let tempSpecs = await getBasicSpecs(yearVerification, index, specsOptions);
                    specsTable = tempSpecs;
                }
            })
            .catch(error => {
                console.error(error);
                setOpen(true);
            });
        return specsTable;
    }

    const getModels = async (assets) => {
        try {
            let tempAssets = [];
            const promise = assets.map(async (assetInfo, index) => {
                if (assetInfo.classificationId !== null && assetInfo.year !== null) {
                    if (assetInfo.classificationId !== "0" && assetInfo.classificationId !== 0) {
                        if (assetInfo.modelId !== "999999") {
                            const rawModelId = assetInfo.modelId;
                            const rawYear = assetInfo.year;
                            const params = {
                                modelId: rawModelId,
                                year: rawYear
                            }
                            const resVal = await get_ewValue(params);
                            if (rawYear === resVal.year) {
                                const res = await get_ewModel(rawModelId);
                                const specs = await getEwBasicSpecs(res, index, rawYear);
                                const assetData = {
                                    category: assetInfo.categoryName,
                                    manufacturer: assetInfo.manufacturerName,
                                    year: assetInfo.year,
                                    hours: assetInfo.hours,
                                    modelId: rawModelId,
                                    rows: specs.rows,
                                    columns: specs.columns
                                };
                                tempAssets.push(assetData);
                            } else {
                                tempAssets.push({modelId: 'No data'});
                            }
                        } else {
                            const assetData = {
                                modelId: 999999,
                                message: `The modelID: ${assetInfo.modelId} associated to the asset cannot be searched with the tool`
                            };
                            tempAssets.push(assetData);
                            setCanEvaluate(false);
                            setCanCalculate(false);
                        }
                    } else {
                        if (assetInfo.vin !== null) {
                            const rawVin = assetInfo.vin;
                            const rawYear = assetInfo.year;
                            const tableSpecs = await getVin(rawVin, index, rawYear);
                            const assetData = {
                                usage: handleIntInputChange(assetInfo?.mileage),
                                condition: condition,
                                year: rawYear,
                                vin: rawVin,
                                rows: tableSpecs.rows,
                                columns: tableSpecs.columns,
                                options: tableSpecs.specsOptions
                            };
                            tempAssets.push(assetData);
                        } else {
                            tempAssets.push({columns: "Incomplete Data", rows:assetInfo.vin === null && "VIN"});
                        }
                    }
                } else {
                    tempAssets.push({columns: "Incomplete Data", rows: assetInfo.classificationId === null && assetInfo.year === null ? "Classification Name and Year" : assetInfo.year !== null ? "Classification Name" : "Year"});
                }
            });
            await Promise.all(promise);
            return tempAssets;
        } catch (e) {
            console.log(e);
            setOpen(true);
        }
    }

    const getData = () => {
        const adjValueLines = dataGraph.map(obj => obj.adjValueLine);
        if (equipCost.current.value && downPay.current.value) {
            let doPay = parseIntNumberWithSeparator(downPay.current.value);
            let oAmount = parseIntNumberWithSeparator(originalEquipCost.current.value)
            let oNil = parseIntNumberWithSeparator(originalNil.current.value);
            let oDownPay = parseIntNumberWithSeparator(originalDownPay.current.value);
            if (doPay > oNil + oDownPay){
                setOpenError(true);
            } else {
                const newNil = oNil + oDownPay - doPay;
                setNilValue(handleIntInputChange(newNil));
                downPayPer.current.value = ((doPay*100)/oAmount).toFixed(2);
                getDeal(doPay, newNil, adjValueLines, null);
                setIsDisabled(false);
            }
        }
    };

    const calcData = (graphData) => {
        const adjValueLines = graphData.map(obj => obj.adjValueLine);
        if (equipCost.current.value && downPay.current.value) {
            let doPay = parseIntNumberWithSeparator(downPay.current.value);
            let oAmount = parseIntNumberWithSeparator(originalEquipCost.current.value)
            let oNil = parseIntNumberWithSeparator(originalNil.current.value);
            let oDownPay = parseIntNumberWithSeparator(originalDownPay.current.value);
            if (doPay > oNil + oDownPay){
                setOpenError(true);
            } else {
                const newNil = oNil + oDownPay - doPay;
                setNilValue(handleIntInputChange(newNil));
                downPayPer.current.value = ((doPay*100)/oAmount).toFixed(2);
                getDeal(doPay, newNil, adjValueLines, graphData);
                setIsDisabled(false);
            }
        }
    };

    const calculateDepreciation = async () => {
        const depreciationPercentages = [100, 86.90, 76.20, 72.16, 59.87, 48.22, 42.69, 35.48];
        const monthValues = [];
        let currentValue = parseIntNumberWithSeparator(equipCost.current.value);

        const initialAdjValue = Math.round(currentValue * (parseFloat(recoveryPer.current.value) / 100));
        monthValues.push({
            valueLine: currentValue,
            adjValueLine: initialAdjValue
        });

        for (let year = 0; year < depreciationPercentages.length - 1; year++) {
            const startPercentage = depreciationPercentages[year];
            const endPercentage = depreciationPercentages[year + 1];
            const differencePerYear = startPercentage - endPercentage;
            const differencePerMonth = differencePerYear / 12;

            for (let month = 0; month < 12; month++) {
                currentValue -= (currentValue * (differencePerMonth / 100));
                const adjMonthValue = Math.round(currentValue * (parseFloat(recoveryPer.current.value) / 100));
                monthValues.push({
                    valueLine: Math.round(currentValue),
                    adjValueLine: adjMonthValue
                });
            }
        }
        const tempValues = monthValues.slice(0, 84);
        setDataGraph(tempValues);
        calcData(tempValues);
    }

    const getAssetsInfo = async (assets) => {
        let tempAssets = [];
        const promise = assets.map(async (assetInfo, index) => {
            if (assetInfo.vin !== null && assetInfo.year !== null) {
                const rawVin = assetInfo.vin;
                const rawYear = assetInfo.year;
                const tableSpecs = await getVin(rawVin, index, rawYear);
                const assetData = {
                    usage: handleIntInputChange(assetInfo?.mileage),
                    condition: condition,
                    year: assetInfo.year,
                    vin: rawVin,
                    rows: tableSpecs.rows,
                    columns: tableSpecs.columns,
                    options: tableSpecs.specsOptions
                };
                tempAssets.push(assetData);
            } else {
                tempAssets.push({columns: "Incomplete Data", rows: assetInfo.vin === null && assetInfo.year === null ? "VIN and Year": assetInfo.vin !== null ? "Year" : "VIN"});
            }
        });
        await Promise.all(promise);
        return tempAssets;
    };

    const onChangeVin = (e) => {
        let tempVin = e.target.value;
        setVin(tempVin);
    }

    const onChangeModel = (e) => {
        let tempVin = e.target.value;
        setModel(tempVin);
    }

    useEffect(() => {
        const exposures = dataGraph.map(obj => obj.exposure);
        const valueLines = dataGraph.map(obj => obj.valueLine);
        const adjValueLines = dataGraph.map(obj => obj.adjValueLine);
        const labels = [];
        for (let i = 1; i <= dataGraph.length; i++) {
            labels.push(i);
        }

        if (dataGraph[0].exposure !== 0){
            const graph = {
                labels: labels,
                datasets: [
                    {
                        label: "Exposure",
                        color: "green",
                        data: exposures,
                    },
                    {
                        label: "Value line",
                        color: "orange",
                        data: valueLines,
                    },
                    {
                        label: "Expected Recovery",
                        color: "blue",
                        data: adjValueLines,
                    },
                ],
            };
            setOutstandingGapChartData(graph);
        } else {
            const graph = {
                labels: labels,
                datasets: [
                    {
                        label: "Value line",
                        color: "orange",
                        data: valueLines,
                    },
                    {
                        label: "Expected Recovery",
                        color: "blue",
                        data: adjValueLines,
                    },
                ],
            };
            setOutstandingGapChartData(graph);
        }
    }, [dataGraph]);

    useEffect(() => {
        if (tmpObj !== null) {
            let values = assetsValues;
            const isFound = values.find(assetInfo => assetInfo && assetInfo.index === tmpObj.index);
            if (isFound !== undefined) {
                values.map(assetInfo => {
                    if (assetInfo.index === tmpObj.index) {
                        assetInfo.values = tmpObj.values;
                    }
                });
            } else {
                values.push(tmpObj);
            }
            setAssetsValues(values);
            const options = tempOptions.current;
            options.map((assetOp, index) => {
                const deprePercent = tempPercentage.current[index].percentage;
                if (assetOp.engineValue !== null && assetOp.engineValue !== undefined) {
                    const vals = values[index+1]?.values;
                    const engineVal = assetOp.engineValue;
                    let month = 0;
                    let basicEngineVal = 0;
                    let ind = 0;
                    if (values[index+1].basicSpecVals.engineValue !== undefined) {
                        basicEngineVal = values[index+1].basicSpecVals.engineValue;
                    }
                    for (let gI = 0; gI < vals.length; gI++) {
                        let percent = deprePercent[ind];
                        if (month < 12) {
                            const oldValueLine = vals[gI].valueLine;
                            const oldAdjValueLine = vals[gI].adjValueLine;
                            let newValueLine = ((engineVal * (percent/100))/12) + oldValueLine;
                            let newAdjValueLine = ((engineVal * (percent/100))/12) + oldAdjValueLine;
                            if (basicEngineVal !== undefined && basicEngineVal > 0) {
                                newValueLine = newValueLine - ((basicEngineVal * (percent/100))/12);
                                newAdjValueLine = newAdjValueLine - (basicEngineVal * ((percent/100))/12);
                            }
                            const newDataArray = {
                                valueLine: parseInt(newValueLine.toFixed(0)),
                                adjValueLine: parseInt(newAdjValueLine.toFixed(0))
                            }
                            values[index+1].values[gI] = newDataArray;
                            month++;
                        } else {
                            ind++;
                            const oldValueLine = vals[gI].valueLine;
                            const oldAdjValueLine = vals[gI].adjValueLine;
                            let newValueLine = ((engineVal * (deprePercent[ind]/100))/12) + oldValueLine;
                            let newAdjValueLine = ((engineVal * (deprePercent[ind]/100))/12) + oldAdjValueLine;
                            if (basicEngineVal !== undefined && basicEngineVal > 0) {
                                newValueLine = newValueLine - ((basicEngineVal * (percent/100))/12);
                                newAdjValueLine = newAdjValueLine - (basicEngineVal * ((percent/100))/12);
                            }
                            const newDataArray = {
                                valueLine: parseInt(newValueLine.toFixed(0)),
                                adjValueLine: parseInt(newAdjValueLine.toFixed(0))
                            }
                            values[index+1].values[gI] = newDataArray;
                            month = 1;
                        }
                    }
               }
                if (assetOp.transmissionValue !== null && assetOp.transmissionValue !== undefined) {
                    const vals = values[index+1]?.values;
                    const transmissionVal = assetOp.transmissionValue;
                    let month = 0;
                    let basicTransmissionVal = 0;
                    let ind = 0;
                    if (values[index+1].basicSpecVals.transmissionValue !== undefined) {
                        basicTransmissionVal = values[index+1].basicSpecVals.transmissionValue;
                    }
                    for (let gI = 0; gI < vals.length; gI++) {
                        let percent = deprePercent[ind];
                        if (month < 12) {
                            const oldValueLine = vals[gI].valueLine;
                            const oldAdjValueLine = vals[gI].adjValueLine;
                            let newValueLine = ((transmissionVal * (percent/100))/12) + oldValueLine;
                            let newAdjValueLine = ((transmissionVal * (percent/100))/12) + oldAdjValueLine;
                            if (basicTransmissionVal !== undefined && basicTransmissionVal > 0) {
                                newValueLine = newValueLine - ((basicTransmissionVal * (percent/100))/12);
                                newAdjValueLine = newAdjValueLine - (basicTransmissionVal * ((percent/100))/12);
                            }
                            const newDataArray = {
                                valueLine: parseInt(newValueLine.toFixed(0)),
                                adjValueLine: parseInt(newAdjValueLine.toFixed(0))
                            }
                            values[index+1].values[gI] = newDataArray;
                            month++;
                        } else {
                            ind++;
                            const oldValueLine = vals[gI].valueLine;
                            const oldAdjValueLine = vals[gI].adjValueLine;
                            let newValueLine = ((transmissionVal * (deprePercent[ind]/100))/12) + oldValueLine;
                            let newAdjValueLine = ((transmissionVal * (deprePercent[ind]/100))/12) + oldAdjValueLine;
                            if (basicTransmissionVal !== undefined && basicTransmissionVal > 0) {
                                newValueLine = newValueLine - ((basicTransmissionVal * (percent/100))/12);
                                newAdjValueLine = newAdjValueLine - (basicTransmissionVal * ((percent/100))/12);
                            }
                            const newDataArray = {
                                valueLine: parseInt(newValueLine.toFixed(0)),
                                adjValueLine: parseInt(newAdjValueLine.toFixed(0))
                            }
                            values[index+1].values[gI] = newDataArray;
                            month = 1;
                        }
                    }
               }
                if (assetOp.rearAxleValue !== null && assetOp.rearAxleValue !== undefined) {
                    const vals = values[index+1]?.values;
                    const rearAxleVal = assetOp.rearAxleValue;
                    let month = 0;
                    let basicRearAxelVal = 0;
                    let ind = 0;
                    if (values[index+1].basicSpecVals.rearAxleValue !== undefined) {
                        basicRearAxelVal = values[index+1].basicSpecVals.rearAxleValue;
                    }
                    for (let gI = 0; gI < vals.length; gI++) {
                        let percent = deprePercent[ind];
                        if (month < 12) {
                            const oldValueLine = vals[gI].valueLine;
                            const oldAdjValueLine = vals[gI].adjValueLine;
                            let newValueLine = ((rearAxleVal * (percent/100))/12) + oldValueLine;
                            let newAdjValueLine = ((rearAxleVal * (percent/100))/12) + oldAdjValueLine;
                            if (basicRearAxelVal !== undefined && basicRearAxelVal > 0) {
                                newValueLine = newValueLine - ((basicRearAxelVal * (percent/100))/12);
                                newAdjValueLine = newAdjValueLine - (basicRearAxelVal * ((percent/100))/12);
                            }
                            const newDataArray = {
                                valueLine: parseInt(newValueLine.toFixed(0)),
                                adjValueLine: parseInt(newAdjValueLine.toFixed(0))
                            }
                            values[index+1].values[gI] = newDataArray;
                            month++;
                        } else {
                            ind++;
                            const oldValueLine = vals[gI].valueLine;
                            const oldAdjValueLine = vals[gI].adjValueLine;
                            let newValueLine = ((rearAxleVal * (deprePercent[ind]/100))/12) + oldValueLine;
                            let newAdjValueLine = ((rearAxleVal * (deprePercent[ind]/100))/12) + oldAdjValueLine;
                            if (basicRearAxelVal !== undefined && basicRearAxelVal > 0) {
                                newValueLine = newValueLine - ((basicRearAxelVal * (percent/100))/12);
                                newAdjValueLine = newAdjValueLine - (basicRearAxelVal * ((percent/100))/12);
                            }
                            const newDataArray = {
                                valueLine: parseInt(newValueLine.toFixed(0)),
                                adjValueLine: parseInt(newAdjValueLine.toFixed(0))
                            }
                            values[index+1].values[gI] = newDataArray;
                            month = 1;
                        }
                    }
               }
                if (assetOp.ieValue !== null && assetOp.ieValue !== undefined) {
                    const vals = values[index+1]?.values;
                    const ieVal = assetOp.ieValue;
                    let month = 0;
                    let ind = 0;
                    for (let gI = 0; gI < vals.length; gI++) {
                        let percent = deprePercent[ind];
                        if (month < 12) {
                            const oldValueLine = vals[gI].valueLine;
                            const oldAdjValueLine = vals[gI].adjValueLine;
                            let newValueLine = ((ieVal * (percent/100))/12) + oldValueLine;
                            let newAdjValueLine = ((ieVal * (percent/100))/12) + oldAdjValueLine;
                            const newDataArray = {
                                valueLine: parseInt(newValueLine.toFixed(0)),
                                adjValueLine: parseInt(newAdjValueLine.toFixed(0))
                            }
                            values[index+1].values[gI] = newDataArray;
                            month++;
                        } else {
                            ind++;
                            const oldValueLine = vals[gI].valueLine;
                            const oldAdjValueLine = vals[gI].adjValueLine;
                            let newValueLine = ((ieVal * (deprePercent[ind]/100))/12) + oldValueLine;
                            let newAdjValueLine = ((ieVal * (deprePercent[ind]/100))/12) + oldAdjValueLine;
                            const newDataArray = {
                                valueLine: parseInt(newValueLine.toFixed(0)),
                                adjValueLine: parseInt(newAdjValueLine.toFixed(0))
                            }
                            values[index+1].values[gI] = newDataArray;
                            month = 1;
                        }
                    }
               }
            });
            const totalValues = values.find(element => element.index === 'total');
            totalValues.values = [{exposure: 0, valueLine: 0, adjValueLine: 0}]
            values.map(assetData => {
                if (assetData.index !== "total" && assetData.values.length !== undefined) {
                    assetData.values.map((val,idx) => {
                        if (totalValues.values[idx]) {
                            totalValues.values[idx].valueLine += val.valueLine;
                            totalValues.values[idx].adjValueLine += val.adjValueLine;
                        } else {
                            totalValues.values[idx] = {
                                exposure: 0,
                                valueLine: val.valueLine,
                                adjValueLine: val.adjValueLine
                            };
                        }
                    })
                }
            });
            const tempValues = totalValues.values;
            const equipAmount = tempValues[0].valueLine;
            equipCost.current.value = equipAmount !== 0 ? handleIntInputChange(equipAmount) : originalEquipCost.current.value;
            setDataGraph(tempValues);
            if (assetsValues.length - 1 === asset.length){
                handleScroll();
            }
        }
    }, [tmpObj, tempOptions]);

    useEffect(() => {
        const fetchData = async (execution_id) => {
            const data = await fetchDealData(execution_id);
            if (data != null) {
                setIsLoading(true);
                const date = {
                    month: currentMonth + 1,
                    year: currentYear
                };
                //mga descomentar antes de subir a prod
                await addLog(execution_id, date);
                const recType = data?.record_type ? data?.record_type.split(" ") : ['TR'];
                const isBl = recType[0] === "BL";
                setIsBlDeal(isBl);
                setDealInfo(data);
                let rawAssets = data.assets;
                if (data.assets.length > 0) {
                    let updatedAssests = [];
                    let rawPercentage = [];
                    let poorArray = new Array(data.assets.length).fill("Poor");
                    rawAssets.map((item, index) => {
                        const updatedAsset = asset.map(section => {
                            if (item.key === 'Mileage') {
                                return { ...section, value: item.mileage };
                            }
                            if (item.key === 'Brand') {
                                return { ...section, value: item.vehicle_name };
                            }
                            if (item.key === 'Type') {
                                return { ...section, value: item.description };
                            }
                            return section;
                        });
                        updatedAssests.push(updatedAsset);
                        const tmpPer = {index: index, percentage: null};
                        rawPercentage.push(tmpPer);
                    })
                    setConditions(poorArray);
                    tempPercentage.current = rawPercentage;
                    temporal.current = poorArray;
                    setAsset(updatedAssests);
                }
                const riskLetter = data.pd_risk_rating;
                const oNil = parseIntNumberWithSeparator(Math.round(data.nil));
                opCosts.current.value = isBl ? 13.50 : 18;
                otherCosts.current.value = isBl ? 2 : 7.5;
                riskClass.current.value = data.pd_risk_rating;
                riskClassPd.current.value = (data.pd * 100).toFixed(2);
                originalEquipCost.current.value = handleIntInputChange(Math.round(data.amount));
                equipCost.current.value = handleIntInputChange(Math.round(data.amount));
                adjEquipCost.current.value = handleIntInputChange(Math.round(data.amount));
                downPay.current.value = handleIntInputChange(Math.round(data.down_payment));
                originalDownPay.current.value = handleIntInputChange(Math.round(data.down_payment));
                periods.current.value = data.term === "null" || data.term === null || data.term === 1 || data.term === 0 ? 36 : data.term;
                nil.current.value = handleIntInputChange(oNil);
                originalNil.current.value = handleIntInputChange(oNil);
                downPayPer.current.value = ((data.down_payment*100)/data.amount).toFixed(2);
                recoveryPer.current.value = riskLetter === "A" ? 19 : riskLetter === "B" ? 26 : riskLetter === "C" ? 33 : riskLetter === "D" ? 35 : riskLetter === "E" ? 38 : riskLetter === "F" ? 40 : 0;
                lgd.current.value = 100 - recoveryPer.current.value;
                setOpportunityName(data?.opportunity_name);
                let feeIn = calcFeeIncome(Math.round(data.nil));
                minTargetProfit.current.value = 0;
                maxTargetProfit.current.value = 5;
                setFeeIncome(feeIn);
                setNilValue(handleIntInputChange(oNil));
                const dateStr = data.execution_date_time;
                const dateObj = new Date(dateStr);
                const formattedDate = dateObj.toLocaleDateString("en-US", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                });
                let tempDeal = {
                    key: data.opportunity_id,
                    executedAt: formattedDate,
                    executionID: data.executionID !== undefined ? data.executionID : data.execution_id,
                    opportunityID: data.opportunity_id,
                    opportunityName: data.opportunity_name,
                    root$amount: handleIntInputChange(data?.amount),
                    root$down_payment: handleIntInputChange(data?.down_payment),
                    root$nil: handleIntInputChange(oNil),
                    root$term: data?.term,
                    final_decision: data?.final_decision,
                    scoring_engine_score: data?.score,
                    pd: parseFloat(data.pd),
                    pd_risk_rating: data?.pd_risk_rating,
                    score_decision: data?.score_decision,
                    assets: data?.assets,
                };
                setDeal(tempDeal);
                let res = [];
                let isGood = true;
                if (isBl) {
                    res = await getModels(rawAssets);
                } else {
                    res = await getAssetsInfo(rawAssets);
                }
                if (res !== undefined && res.length > 0) {
                    res.map(asset => {
                        if (asset.columns === undefined || asset.columns === "Incomplete Data" || asset.rows === undefined) {
                            isGood = false;
                        }
                    });
                } else {
                    isGood = false;
                }
                if (isGood) {
                    setAssets(res);
                    await lookDeal(execution_id);
                } else {
                    setCannotWrite(false);
                    setIsDisabled(false);
                    setIsNotComplete(true);
                    await lookDeal(execution_id);
                }
                localStorage.removeItem("execution_id");
                setIsLoading(false);
            }
        };
        const execution_id = localStorage.hasOwnProperty("execution_id") ? localStorage.getItem("execution_id") : searchParams.get('execution_id');

        if(execution_id !== undefined && execution_id !== null && execution_id !== '') {
            fetchData(execution_id);
        }

    }, [localStorage, searchParams]);

    useEffect(async () => {
        if (deal) {
            setIsLoading(true);
            const date = {
                month: currentMonth + 1,
                year: currentYear
            };
            //mga descomentar antes de subir a prod
            await addLog(deal.executionID, date);
            const recType = deal.root$record_type ? deal.root$record_type.split(" ") : ['TR'];
            const isBl = recType[0] === "BL";
            const riskLetter = deal.pd_risk_rating.value;
            let rawAssets = deal.assets;
            setIsBlDeal(isBl);
            if (deal.assets.length > 0) {
                let updatedAssests = [];
                let rawPercentage = [];
                let poorArray = new Array(deal.assets.length).fill("Poor");
                rawAssets.map((item, index) => {
                    const updatedAsset = asset.map(section => {
                        if (section.key === 'Mileage') {
                            return {...section, value: item.mileage};
                        }
                        if (section.key === 'Brand') {
                            return {...section, value: item.vehicle_name};
                        }
                        if (section.key === 'Type') {
                            return {...section, value: item.description};
                        }
                        return section;
                    });
                    updatedAssests.push(updatedAsset);
                    const tmpPer = {index: index, percentage: null};
                    rawPercentage.push(tmpPer);
                });
                setConditions(poorArray);
                temporal.current = poorArray;
                tempPercentage.current = rawPercentage;
                setAsset(updatedAssests);
            }
            riskClass.current.value = deal.pd_risk_rating.value;
            riskClassPd.current.value = (deal.pd * 100).toFixed(2);
            originalEquipCost.current.value = handleIntInputChange(deal.root$amount);
            equipCost.current.value = handleIntInputChange(deal.root$amount);
            adjEquipCost.current.value = handleIntInputChange(deal.root$amount);
            downPay.current.value = handleIntInputChange(deal.root$down_payment);
            originalDownPay.current.value = handleIntInputChange(deal.root$down_payment);
            periods.current.value = deal.root$term === "null" || deal.root$term === null || deal.root$term === 1 || deal.root$term === 0 ? 36 : deal.root$term;
            nil.current.value = handleIntInputChange(deal.root$nil);
            originalNil.current.value = handleIntInputChange(deal.root$nil);
            opCosts.current.value = isBl ? 13.50 : 18;
            otherCosts.current.value = isBl ? 2 : 7.5;
            downPayPer.current.value = ((deal.root$down_payment * 100) / deal.root$amount).toFixed(2);
            recoveryPer.current.value = riskLetter === "A" ? 19 : riskLetter === "B" ? 26 : riskLetter === "C" ? 33 : riskLetter === "D" ? 35 : riskLetter === "E" ? 38 : riskLetter === "F" ? 40 : 0;
            lgd.current.value = 100 - recoveryPer.current.value;
            setOpportunityName(deal?.opportunityName);
            let feeIn = calcFeeIncome(deal.root$nil);
            minTargetProfit.current.value = 0;
            maxTargetProfit.current.value = 5;
            setFeeIncome(feeIn);
            setNilValue(handleIntInputChange(deal.root$nil));
            let res = [];
            let isGood = true;
            if (isBl) {
                res = await getModels(rawAssets);
            } else {
                res = await getAssetsInfo(rawAssets);
            }
            if (res !== undefined && res.length > 0) {
                res.map(asset => {
                    if (asset.columns === undefined || asset.rows === undefined) {
                        isGood = false;
                    }
                });
            } else {
                isGood = false;
            }
            if (isGood) {
                setAssets(res);
                await lookDeal(deal?.executionID);
            } else {
                await lookDeal(deal?.executionID);
                setCannotWrite(false);
                setIsDisabled(false);
                setIsNotComplete(true);
            }
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (scrollToDiv) {
            const targetDiv = document.getElementById('linkToGrid');
            if (targetDiv) {
                targetDiv.scrollIntoView({ behavior: 'smooth' });
            }
            setScrollToDiv(false);
        }
    }, [scrollToDiv]);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox py={3}>
                {isLoading ? (
                    <>
                        <SoftBox py={3}>
                            <CircularProgress color="info" />
                        </SoftBox>
                    </>
                ) : (
                    <>
                        <SoftBox>
                            <Grid container>
                                <Grid item xs={12} sm={6} cl={3} mr={2}>
                                    <h2>{opportunityName}</h2>
                                </Grid>
                            </Grid>
                            {dealHistory ? (
                                <>
                                    <Grid container>
                                        <Grid item xs={12} sm={3} cl={3} mr={2}>
                                            <h3>Calculation history</h3>
                                        </Grid>
                                    </Grid>
                                    {isSending ? (
                                        <>
                                            <Grid container>
                                                <SoftBox py={3}>
                                                    <CircularProgress color="info" />
                                                </SoftBox>
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid container>
                                            <SoftBox
                                                sx={{
                                                    "& .MuiTableRow-root:not(:last-child)": {
                                                        "& td": {
                                                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                                `${borderWidth[1]} solid ${borderColor}`,
                                                        },
                                                    },
                                                }}
                                                mb={3}
                                            >
                                                <Table columns={calcColumns} rows={calcRows} />
                                            </SoftBox>
                                        </Grid>
                                    )}
                                </>
                            ) : (
                                <SoftAlert color="error" dismissible>No calculation history for this deal</SoftAlert>
                            )}
                        </SoftBox>
                        {canEvaluate ?
                            isBlDeal ? (
                                <>
                                    {assets.length > 0 && assets.map((infoAsset,index) => {
                                        if (infoAsset.columns === "Incomplete Data") {
                                            return (
                                                <SoftAlert color="error" dismissible>Variable {infoAsset.rows} empty. Make sure data is complete before running the tool</SoftAlert>
                                            )
                                        } else {
                                            if (infoAsset.modelId === undefined && infoAsset.vin !== null) {
                                                return (
                                                    <>
                                                        <SoftBox mb={3} key={index}>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <Paper elevation={1} sx={selectStyle}>
                                                                        <Box padding={1}>
                                                                            <Grid container spacing={1} justifyContent="left" alignItems="center">
                                                                                <Grid item>
                                                                                    <Typography variant="body2" style={{color: '#344767'}}>Usage (miles)</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                                                        <OutlinedInput
                                                                                            type="text"
                                                                                            name="usage"
                                                                                            value={infoAsset.usage}
                                                                                            disabled
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Paper elevation={1} sx={selectStyle}>
                                                                        <Box padding={1}>
                                                                            <Grid container spacing={1} justifyContent="left" alignItems="center">
                                                                                <Grid item>
                                                                                    <Typography variant="body2" style={{color: '#344767'}}>Year</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                                                        <OutlinedInput
                                                                                            type="number"
                                                                                            name="year"
                                                                                            value={infoAsset.year}
                                                                                            disabled
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Paper elevation={1} sx={selectStyle}>
                                                                        <Box padding={1}>
                                                                            <Grid container spacing={2} justifyContent="left" alignItems="center">
                                                                                <Grid item>
                                                                                    <Typography variant="body2" style={{color: '#344767'}}>Condition</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <FormControl fullWidth>
                                                                                        <Select
                                                                                            labelId="Condition"
                                                                                            id="conditionValue"
                                                                                            value={conditions[index]}
                                                                                            label="Age"
                                                                                            onChange={(e) => {
                                                                                                const val = e.target.value;
                                                                                                const foundIndex = temporal.current[index];
                                                                                                if (foundIndex !== undefined) {
                                                                                                    temporal.current[index] = val;
                                                                                                } else {
                                                                                                    temporal.current.push(val);
                                                                                                }
                                                                                                handleChange(e, index);
                                                                                            }}
                                                                                            sx={{width: '100%'}}
                                                                                        >
                                                                                            <MenuItem value={'Good'}>Good</MenuItem>
                                                                                            <MenuItem value={'Fair'}>Fair</MenuItem>
                                                                                            <MenuItem value={'Poor'}>Poor</MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={6} sm={6} cl={6}>
                                                                    <Collapse in={openWarning}>
                                                                        <Alert severity="error"
                                                                               action={
                                                                                   <IconButton
                                                                                       aria-label="close"
                                                                                       color="inherit"
                                                                                       size="small"
                                                                                       onClick={() => {
                                                                                           setOpenWarning(false);
                                                                                       }}
                                                                                   >
                                                                                       <CloseIcon fontSize="inherit" />
                                                                                   </IconButton>
                                                                               }
                                                                               sx={{ mb: 2 }}
                                                                        >
                                                                            Please add the usage (miles) of the asset
                                                                        </Alert>
                                                                    </Collapse>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                    <Typography variant="body2" style={{fontSize: '0.9rem',
                                                                        fontWeight: 400, lineHeight: '1.1375em',
                                                                        letterSpacing: '0.00938em', padding: '0 0 0 15px'}}>
                                                                        VIN
                                                                    </Typography>
                                                                    <SoftInput placeholder="VIN" value={infoAsset.vin}
                                                                               onChange={onChangeVin}></SoftInput>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="engineSelect"
                                                                        getOptionLabel={(option) => option.optionName}
                                                                        options={infoAsset.options.enginesOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                                        groupBy={(option) => option.firstLetter}
                                                                        onChange={(event, value) => {
                                                                            handleNewSpec(value, "engineSelect", index);
                                                                        }}
                                                                        // sx={{ width: 300 }}
                                                                        renderInput={(params) => <TextField sx={{padding: "9px 0"}} {...params} label="Engine" />}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="transOptions"
                                                                        getOptionLabel={(option) => option.optionName}
                                                                        options={infoAsset.options.transOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                                        groupBy={(option) => option.firstLetter}
                                                                        onChange={(event, value) => {
                                                                            handleNewSpec(value, "transOptions", index);
                                                                        }}
                                                                        // sx={{ width: 300 }}
                                                                        renderInput={(params) => <TextField sx={{padding: "9px 0"}} {...params} label="Transmission" />}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="raOptions"
                                                                        getOptionLabel={(option) => option.optionName}
                                                                        options={infoAsset.options.rearAxleOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                                        groupBy={(option) => option.firstLetter}
                                                                        onChange={(event, value) => {
                                                                            handleNewSpec(value, "raOptions", index);
                                                                        }}
                                                                        // sx={{ width: 300 }}
                                                                        renderInput={(params) => <TextField sx={{padding: "9px 0"}} {...params} label="Rear Axle" />}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="ieOptions"
                                                                        getOptionLabel={(option) => option.optionName}
                                                                        options={infoAsset.options.ieOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                                        groupBy={(option) => option.firstLetter}
                                                                        onChange={(event, value) => {
                                                                            handleNewSpec(value, "ieOptions", index);
                                                                        }}
                                                                        // sx={{ width: 300 }}
                                                                        renderInput={(params) => <TextField sx={{padding: "9px 0"}} {...params} label="Interior Equipment" />}
                                                                    />
                                                                </Grid>
                                                                {isCalculating && (
                                                                    <>
                                                                        <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                            <CircularProgress color="info" />
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={6} sm={6} cl={6}>
                                                                    <Collapse in={open}>
                                                                        <Alert severity="error"
                                                                               action={
                                                                                   <IconButton
                                                                                       aria-label="close"
                                                                                       color="inherit"
                                                                                       size="small"
                                                                                       onClick={() => {
                                                                                           setOpen(false);
                                                                                       }}
                                                                                   >
                                                                                       <CloseIcon fontSize="inherit" />
                                                                                   </IconButton>
                                                                               }
                                                                               sx={{ mb: 2 }}
                                                                        >
                                                                            No results found with those variables
                                                                        </Alert>
                                                                    </Collapse>
                                                                </Grid>
                                                            </Grid>
                                                        </SoftBox>
                                                        {infoAsset.rows && (
                                                            <SoftBox
                                                                sx={{
                                                                    "& .MuiTableRow-root:not(:last-child)": {
                                                                        "& td": {
                                                                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                                                `${borderWidth[1]} solid ${borderColor}`,
                                                                        },
                                                                    },
                                                                }}
                                                                mb={3}
                                                            >
                                                                <Table columns={infoAsset.columns} rows={infoAsset.rows} />
                                                            </SoftBox>
                                                        )}
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        <SoftBox mb={3}>
                                                            <Grid container xs={12}>
                                                                <Grid item xs={3}>
                                                                    <Paper elevation={1} sx={selectStyle}>
                                                                        <Box padding={1}>
                                                                            <Grid container spacing={1} justifyContent="left" alignItems="center">
                                                                                <Grid item xs={6}>
                                                                                    <Typography variant="body2">Category</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                                                        <OutlinedInput
                                                                                            type="text"
                                                                                            name="category"
                                                                                            disabled
                                                                                            value={infoAsset.category}
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Paper elevation={1} sx={selectStyle}>
                                                                        <Box padding={1}>
                                                                            <Grid container spacing={1} justifyContent="left" alignItems="center">
                                                                                <Grid item xs={6}>
                                                                                    <Typography variant="body2">Manufacturer</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                                                        <OutlinedInput
                                                                                            type="text"
                                                                                            name="manufacturer"
                                                                                            disabled
                                                                                            value={infoAsset.manufacturer}
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Paper elevation={1} sx={selectStyle}>
                                                                        <Box padding={1}>
                                                                            <Grid container spacing={1} justifyContent="left" alignItems="center">
                                                                                <Grid item xs={4}>
                                                                                    <Typography variant="body2">Hours</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                                                        <OutlinedInput
                                                                                            type="text"
                                                                                            name="manufacturer"
                                                                                            disabled
                                                                                            value={infoAsset.hours}
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Paper elevation={1} sx={selectStyle}>
                                                                        <Box padding={1}>
                                                                            <Grid container spacing={1} justifyContent="left" alignItems="center">
                                                                                <Grid item xs={4}>
                                                                                    <Typography variant="body2">Year</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                                                        <OutlinedInput
                                                                                            type="text"
                                                                                            name="year"
                                                                                            disabled
                                                                                            value={infoAsset.year}
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={12} sm={3} cl={3} mr={2} pl={1}>
                                                                    <Typography variant="body2" style={{fontSize: '0.9rem',
                                                                        fontWeight: 400, lineHeight: '1.1375em',
                                                                        letterSpacing: '0.00938em', padding: '0 0 0 15px'}}>
                                                                        Model
                                                                    </Typography>
                                                                    <SoftInput placeholder="Model" disabled value={infoAsset.modelId}
                                                                               onChange={onChangeModel}></SoftInput>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={6} sm={6} cl={6}>
                                                                    <Collapse in={open}>
                                                                        <Alert severity="error"
                                                                               action={
                                                                                   <IconButton
                                                                                       aria-label="close"
                                                                                       color="inherit"
                                                                                       size="small"
                                                                                       onClick={() => {
                                                                                           setOpen(false);
                                                                                       }}
                                                                                   >
                                                                                       <CloseIcon fontSize="inherit" />
                                                                                   </IconButton>
                                                                               }
                                                                               sx={{ mb: 2 }}
                                                                        >
                                                                            No results found with those variables
                                                                        </Alert>
                                                                    </Collapse>
                                                                </Grid>
                                                            </Grid>
                                                        </SoftBox>
                                                        {infoAsset.rows && infoAsset.modelId !== 999999 && (
                                                            <SoftBox
                                                                sx={{
                                                                    "& .MuiTableRow-root:not(:last-child)": {
                                                                        "& td": {
                                                                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                                                `${borderWidth[1]} solid ${borderColor}`,
                                                                        },
                                                                    },
                                                                }}
                                                                mb={3}
                                                            >
                                                                <Table columns={infoAsset.columns} rows={infoAsset.rows} />
                                                            </SoftBox>
                                                        )}
                                                    </>
                                                )
                                            }
                                        }
                                    })}
                                </>
                            ) : (
                                <>
                                    {assets.length > 0 && assets.map((infoAsset, index) => {
                                        if (infoAsset.columns === "Incomplete Data") {
                                            return (
                                                <SoftAlert color="error" dismissible>Variable {infoAsset.rows} empty. Make sure data is complete before running the tool</SoftAlert>
                                                )
                                        } else {
                                            return (
                                                <>
                                                    <SoftBox mb={3} key={index}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Paper elevation={1} sx={selectStyle}>
                                                                    <Box padding={1}>
                                                                        <Grid container spacing={1} justifyContent="left" alignItems="center">
                                                                            <Grid item>
                                                                                <Typography variant="body2" style={{color: '#344767'}}>Usage (miles)</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                                                    <OutlinedInput
                                                                                        type="text"
                                                                                        name="usage"
                                                                                        value={infoAsset.usage}
                                                                                        disabled
                                                                                    />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Paper elevation={1} sx={selectStyle}>
                                                                    <Box padding={1}>
                                                                        <Grid container spacing={1} justifyContent="left" alignItems="center">
                                                                            <Grid item>
                                                                                <Typography variant="body2" style={{color: '#344767'}}>Year</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                                                    <OutlinedInput
                                                                                        type="number"
                                                                                        name="year"
                                                                                        value={infoAsset.year}
                                                                                        disabled
                                                                                    />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Paper elevation={1} sx={selectStyle}>
                                                                    <Box padding={1}>
                                                                        <Grid container spacing={2} justifyContent="left" alignItems="center">
                                                                            <Grid item>
                                                                                <Typography variant="body2" style={{color: '#344767'}}>Condition</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <FormControl fullWidth>
                                                                                    <Select
                                                                                        labelId="Condition"
                                                                                        id="conditionValue"
                                                                                        value={conditions[index]}
                                                                                        label="Age"
                                                                                        onChange={(e) => {
                                                                                            const val = e.target.value;
                                                                                            const foundIndex = temporal.current[index];
                                                                                            if (foundIndex !== undefined) {
                                                                                                temporal.current[index] = val;
                                                                                            } else {
                                                                                                temporal.current.push(val);
                                                                                            }
                                                                                            handleChange(e, index);
                                                                                        }}
                                                                                        sx={{width: '100%'}}
                                                                                    >
                                                                                        <MenuItem value={'Good'}>Good</MenuItem>
                                                                                        <MenuItem value={'Fair'}>Fair</MenuItem>
                                                                                        <MenuItem value={'Poor'}>Poor</MenuItem>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={6} sm={6} cl={6}>
                                                                <Collapse in={openWarning}>
                                                                    <Alert severity="error"
                                                                           action={
                                                                               <IconButton
                                                                                   aria-label="close"
                                                                                   color="inherit"
                                                                                   size="small"
                                                                                   onClick={() => {
                                                                                       setOpenWarning(false);
                                                                                   }}
                                                                               >
                                                                                   <CloseIcon fontSize="inherit" />
                                                                               </IconButton>
                                                                           }
                                                                           sx={{ mb: 2 }}
                                                                    >
                                                                        Please add the usage (miles) of the asset
                                                                    </Alert>
                                                                </Collapse>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                <Typography variant="body2" style={{fontSize: '0.9rem',
                                                                    fontWeight: 400, lineHeight: '1.1375em',
                                                                    letterSpacing: '0.00938em', padding: '0 0 0 15px'}}>
                                                                    VIN
                                                                </Typography>
                                                                <SoftInput placeholder="VIN" value={infoAsset.vin}
                                                                           onChange={onChangeVin}></SoftInput>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container mt={2}>
                                                            <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="engineSelect"
                                                                    getOptionLabel={(option) => option.optionName}
                                                                    options={infoAsset.options.enginesOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                                    groupBy={(option) => option.firstLetter}
                                                                    onChange={(event, value) => {
                                                                        handleNewSpec(value, "engineSelect", index);
                                                                    }}
                                                                    renderInput={(params) => <TextField sx={{padding: "9px 0"}} {...params} label="Engine" />}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="transOptions"
                                                                    getOptionLabel={(option) => option.optionName}
                                                                    options={infoAsset.options.transOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                                    groupBy={(option) => option.firstLetter}
                                                                    onChange={(event, value) => {
                                                                        handleNewSpec(value, "transOptions", index);
                                                                    }}
                                                                    renderInput={(params) => <TextField sx={{padding: "9px 0"}} {...params} label="Transmission" />}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="raOptions"
                                                                    getOptionLabel={(option) => option.optionName}
                                                                    options={infoAsset.options.rearAxleOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                                    groupBy={(option) => option.firstLetter}
                                                                    onChange={(event, value) => {
                                                                        handleNewSpec(value, "raOptions", index);
                                                                    }}
                                                                    renderInput={(params) => <TextField sx={{padding: "9px 0"}} {...params} label="Rear Axle" />}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="ieOptions"
                                                                    getOptionLabel={(option) => option.optionName}
                                                                    options={infoAsset.options.ieOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                                    groupBy={(option) => option.firstLetter}
                                                                    onChange={(event, value) => {
                                                                        handleNewSpec(value, "ieOptions", index);
                                                                    }}
                                                                    renderInput={(params) => <TextField sx={{padding: "9px 0"}} {...params} label="Interior Equipment" />}
                                                                />
                                                            </Grid>
                                                            {isCalculating && (
                                                                <>
                                                                    <Grid item xs={12} sm={3} cl={3} mr={2}>
                                                                        <CircularProgress color="info" />
                                                                    </Grid>
                                                                </>
                                                            )}
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={6} sm={6} cl={6}>
                                                                <Collapse in={open}>
                                                                    <Alert severity="error"
                                                                           action={
                                                                               <IconButton
                                                                                   aria-label="close"
                                                                                   color="inherit"
                                                                                   size="small"
                                                                                   onClick={() => {
                                                                                       setOpen(false);
                                                                                   }}
                                                                               >
                                                                                   <CloseIcon fontSize="inherit" />
                                                                               </IconButton>
                                                                           }
                                                                           sx={{ mb: 2 }}
                                                                    >
                                                                        No results found with those variables
                                                                    </Alert>
                                                                </Collapse>
                                                            </Grid>
                                                        </Grid>
                                                    </SoftBox>
                                                    {infoAsset.rows && (
                                                        <SoftBox
                                                            sx={{
                                                                "& .MuiTableRow-root:not(:last-child)": {
                                                                    "& td": {
                                                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                                            `${borderWidth[1]} solid ${borderColor}`,
                                                                    },
                                                                },
                                                            }}
                                                            mb={3}
                                                        >
                                                            <Table columns={infoAsset.columns} rows={infoAsset.rows} />
                                                        </SoftBox>
                                                    )}
                                                </>
                                            )
                                        }
                                    })}
                                </>
                            )
                            : (
                                <SoftAlert color="error" dismissible>The modelID: 999999 associated to the evaluated assets cannot be searched with the tool</SoftAlert>
                            )
                        }
                        {isNotComplete && (
                            <SoftAlert color="error" dismissible>Could not find a configuration/model with that set of parameters</SoftAlert>
                        )}
                    </>
                )}
                <SoftBox mb={3}>
                    <Grid container>
                        <Grid item xs={8} sm={8} cl={12}>
                            <Collapse in={residualError}>
                                <SoftAlert color="error" dismissible>Residual Value information is not available for this asset with that set of specs</SoftAlert>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} mb={2} id="linkToGrid">
                        <Grid item xs={4}>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">PD Risk Grade</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl disabled sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="riskClass"
                                                    inputRef={riskClass}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">PD</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="riskClassPd"
                                                    inputRef={riskClassPd}
                                                    disabled
                                                    id="outlined-adornment-amount"
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Operating costs</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="number"
                                                    name="opCosts"
                                                    disabled
                                                    inputRef={opCosts}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Funding costs (WACC)</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="number"
                                                    name="fundingCosts"
                                                    value={15.83}
                                                    disabled
                                                    inputRef={fundingCosts}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4} display="none">
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Min Target Profit</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl disabled sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="MinTargetProfit"
                                                    inputRef={minTargetProfit}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Other Income</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="otherCosts"
                                                    disabled
                                                    inputRef={otherCosts}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Percentage of recovery (%)</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="recoveryPer"
                                                    inputRef={recoveryPer}
                                                    disabled={true}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Loss Given Default (LGD)</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="lgd"
                                                    inputRef={lgd}
                                                    disabled={true}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Max Target Profit</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="MaxTargetProfit"
                                                    disabled
                                                    inputRef={maxTargetProfit}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={4}>
                        <Grid item xs={4} mt={1}>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Original Equipment Amount</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="text"
                                                    name="originalEquipCost"
                                                    disabled
                                                    inputRef={originalEquipCost}
                                                    onChange={(e) => {
                                                        const {value} = e.target;
                                                        const numericValue = value.replace(/\D/g, '');
                                                        const formattedValue = handleIntInputChange(numericValue);
                                                        e.target.value = formattedValue;
                                                    }}
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment
                                                        position="start">$</InputAdornment>}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Original NIL</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="text"
                                                    name="nil"
                                                    inputRef={originalNil}
                                                    disabled
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment
                                                        position="start">$</InputAdornment>}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Original Down payment</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="text"
                                                    name="downPay"
                                                    disabled
                                                    inputRef={originalDownPay}
                                                    // value={originalDownPay.current.value}
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment
                                                        position="start">$</InputAdornment>}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle} style={{backgroundColor: !cannotWrite && "#a7b8d2"}}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            {cannotWrite ?
                                                (
                                                    <Typography variant="body2">RR Equipment Amount</Typography>
                                                ) : (

                                                    <Typography variant="body2">Equipment Value</Typography>
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="text"
                                                    name="equipCost"
                                                    disabled={cannotWrite}
                                                    inputRef={equipCost}
                                                    onChange={(e) => {
                                                        const {value} = e.target;
                                                        const numericValue = value.replace(/\D/g, '');
                                                        const formattedValue = handleIntInputChange(numericValue);
                                                        e.target.value = formattedValue;
                                                    }}
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment
                                                        position="start">$</InputAdornment>}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Expected Recovery</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="text"
                                                    name="equipCost"
                                                    disabled
                                                    inputRef={adjEquipCost}
                                                    onChange={(e) => {
                                                        const {value} = e.target;
                                                        const numericValue = value.replace(/\D/g, '');
                                                        const formattedValue = handleIntInputChange(numericValue);
                                                        e.target.value = formattedValue;
                                                    }}
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment
                                                        position="start">$</InputAdornment>}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">NIL</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="text"
                                                    name="nil"
                                                    inputRef={nil}
                                                    disabled
                                                    value={nilValue}
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment
                                                        position="start">$</InputAdornment>}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Down Payment Percentage</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl disabled sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="downPayPer"
                                                    inputRef={downPayPer}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Monthly Payment</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="text"
                                                    name="monthlyPayment"
                                                    disabled
                                                    inputRef={monthlyPayment}
                                                    onChange={(e) => {
                                                        const {value} = e.target;
                                                        const numericValue = value.replace(/\D/g, '');
                                                        const formattedValue = handleIntInputChange(numericValue);
                                                        e.target.value = formattedValue;
                                                    }}
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment
                                                        position="start">$</InputAdornment>}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            {openError ? (
                                <>
                                    <Grid item xs={12} sm={12} cl={12} mt={3}>
                                        <Collapse in={openError}>
                                            <Alert severity="error"
                                                   action={
                                                       <IconButton
                                                           aria-label="close"
                                                           color="inherit"
                                                           size="small"
                                                           onClick={() => {
                                                               setOpenError(false);
                                                           }}
                                                       >
                                                           <CloseIcon fontSize="inherit" />
                                                       </IconButton>
                                                   }
                                                   sx={{ mb: 2 }}
                                            >
                                                The new Down Payment surpasses the original NIL and Down Payment
                                            </Alert>
                                        </Collapse>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item my={3}></Grid>
                            )}
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Down payment</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    type="text"
                                                    name="downPay"
                                                    inputRef={downPay}
                                                    onChange={(e) => {
                                                        const {value} = e.target;
                                                        const numericValue = value.replace(/\D/g, '');
                                                        const formattedValue = handleIntInputChange(numericValue);
                                                        e.target.value = formattedValue;
                                                    }}
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment
                                                        position="start">$</InputAdornment>}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Term (months)</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                name="periods"
                                                inputRef={periods}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Max Annual rate</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="maxAnnualRate"
                                                    disabled={true}
                                                    inputRef={maxAnnualRate}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Min Annual rate</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="minAnnualRate"
                                                    disabled={true}
                                                    inputRef={minAnnualRate}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper elevation={1} sx={paperStyle}>
                                <Box padding={1}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2">Approved Annual rate</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl sx={{width: '100%'}} size="small" variant="outlined">
                                                <OutlinedInput
                                                    name="annualRate"
                                                    disabled={isDisabled}
                                                    inputRef={annualRate}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Grid mt={2} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                {/*<Grid container>*/}
                                {/*    <Grid item xs={12} sm={6} xl={2}>*/}
                                <Button variant="contained" color="dark" disabled={cannotWrite ? !canCalculate : false} onClick={cannotWrite ? getData : calculateDepreciation}>Calculate</Button>
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={6} cl={3}>*/}
                                <SoftButton variant="contained" size="small" color="dark" disabled={isDisabled} onClick={saveDeal}>Save Calculation</SoftButton>
                                {/*</Grid>*/}
                                <Snackbar open={openSucc} autoHideDuration={2000} onClose={handleClose}>
                                    <Alert severity="success" onClose={handleClose} sx={{ width: '100%' }}>
                                        Calculation saved!
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openSent} autoHideDuration={2000} onClose={handleCloseSent}>
                                    <Alert severity="success" onClose={handleCloseSent} sx={{ width: '100%' }}>
                                        Data successfully sent to SalesForce!
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openDel} autoHideDuration={2000} onClose={handleCloseDel}>
                                    <Alert severity="warning" onClose={handleCloseDel} sx={{ width: '100%' }}>
                                        Calculation deleted!
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openSentProblem} autoHideDuration={2000} onClose={handleCloseProblem}>
                                    <Alert severity="warning" onClose={handleCloseProblem} sx={{ width: '100%' }}>
                                        There was a problem sending the data to SaleForce, please check and try again!
                                    </Alert>
                                </Snackbar>
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <GradientLineChart
                                title="Outstanding gap position"
                                height="40.25rem"
                                chart={outstandingGapChartData}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftBox mb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SoftBox>
                                <SoftTypography>Summary - Deal Structure</SoftTypography>
                            </SoftBox>
                            <SoftBox
                                sx={{
                                    "& .MuiTableRow-root:not(:last-child)": {
                                        "& td": {
                                            borderBottom: ({borders: {borderWidth, borderColor}}) =>
                                                `${borderWidth[1]} solid ${borderColor}`,
                                        },
                                    },
                                }}
                            >
                                <Table columns={dealColumns} rows={dealRowsData}/>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12} md={12}>
                                <SoftBox>
                                    <SoftTypography>Summary - Model Results:</SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    sx={{
                                        "& .MuiTableRow-root:not(:last-child)": {
                                            "& td": {
                                                borderBottom: ({borders: {borderWidth, borderColor}}) =>
                                                    `${borderWidth[1]} solid ${borderColor}`,
                                            },
                                        },
                                    }}
                                >
                                    <Table columns={dealColumns} rows={modelRowsData}/>
                                </SoftBox>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <SoftBox>
                                <SoftTypography>Summary - P&L:</SoftTypography>
                            </SoftBox>
                            <SoftBox
                                sx={{
                                    "& .MuiTableRow-root:not(:last-child)": {
                                        "& td": {
                                            borderBottom: ({borders: {borderWidth, borderColor}}) =>
                                                `${borderWidth[1]} solid ${borderColor}`,
                                        },
                                    },
                                }}
                            >
                                <Table columns={annualColumns} rows={plRowsData}/>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
}

// interface SubTypeOption {
//     subtypeId: number;
//     subtypeName: String;
// }

export default Dashboard;
